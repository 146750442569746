.containerDeleteAccountModal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(112, 112, 112, 0.6);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteAccount__title {
  margin-top: 24px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--grey30);
}

.deleteAccount__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--grey30);
  margin-top: 24px;
}

.deleteAccountModal {
  display: flex;
  width: 100%;
  max-width: 570px;
  height: auto;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: var(--secondary-black-color);
}

.circleSVG {
  position: absolute;
  width: 66.67px;
  height: 66.67px;
  border-radius: 50%;
  top: 47px;
}

.trashSVG {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.groupButtonModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-top: 24px;
}

.groupButtonModal > button {
  margin: 0;
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .deleteAccountModal {
    max-width: 624px;
    height: auto;
  }
  .deleteAccount__title {
    font-size: 28px;
    margin-top: 20px;
    line-height: 36px;
  }
  .deleteAccount__description {
    font-size: 16px;
    line-height: 24px;
  }
  .groupButtonModal {
    padding-top: 20px;
  }
}

@media screen and (max-width: 743px) {
  .groupButtonModal {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-top: 16px;
  }

  .groupButtonModal > button {
    width: 100%;
  }

  .deleteAccount__title {
    font-size: 24px;
    margin-top: 16px;
    line-height: 32px;
  }

  .deleteAccount__description {
    font-size: 14px;
    line-height: 20px;
  }

  .deleteAccountModal {
    max-width: 350px;
    height: auto;
  }

  .groupButtonModal {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
}
