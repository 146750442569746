/* .page__infoContainer{
  flex-grow: 0;
} */
.page__subtitle p {
  margin-bottom: 25px;
  text-align: justify;
}
.addautomation__foto {
  max-width: 570px;
  width: 100%;
  height: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.instructionCheckbox__holder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 0 0 80px 0;
  margin-bottom: 5px;
  flex: 1;
}
.instructionCheckbox {
  position: relative;
  height: 24px;
}
.instructionCheckbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -12;
}
.custom__checkbox {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--secondary-black-color);
  border: 1px solid var(--grey20);
  border-radius: 4px;
  position: relative;
}
.custom__checkbox::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../../assets/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.2 ease-in-out;
}
.instructionCheckbox__input:checked + .custom__checkbox {
  background-color: var(--primary-color);
}
.instructionCheckbox__input:checked + .custom__checkbox::before {
  transform: translate(-50%, -50%) scale(1);
}
.automation__conditions {
  color: #eeeeee;
  font-size: 18px;
  text-align: justify;
  cursor: default;
}
.checkError {
  color: var(--error);
}
.automation__conditions a {
  cursor: pointer;
  color: var(--primary-color);
}

.page__infoContainer.automation_instruction {
  margin: 80px 0px 40px 0px;
}

@media screen and (max-width: 1024px) {
  .automation__conditions {
    font-size: 16px;
  }

  .page__infoContainer.automation_instruction {
    margin: 80px 0px 20px 0px;
  }
}

@media screen and (max-width: 743px) {
  .automation__conditions {
    font-size: 14px;
    padding: 0px;
  }
  .instructionCheckbox__holder {
    flex: 0;
    padding: 0 0 20px 0;
  }
}
