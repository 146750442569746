.navbtn__holder {
  display: flex;
  gap: 20px;
}
.errorIcon{
 text-align: center;
 margin-top: 80px;
}
.errorIcon img{
  width: 67px;
}

.verifyAccount__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 36px;
  margin-top: 36px;
  text-align: center;
}
.verifyAccount__subtitle {
  text-align: center;
  font-size: 18px;
  margin-bottom: 36px;
}

.verifyAccount__form {
  max-width: 570px;
  width: 100%;
  margin: 0 auto 8px;
}
@media screen and (max-width: 1024px) {
  .verifyAccount__form {
    max-width: 624px;
    margin: 0 auto 8px;
  }
}
@media screen and (max-width: 743px) {
  .verifyAccount__form {
    margin: 0 auto 8px;
  }
}
.verifyAccount__form .email__errorMassage {
  margin-top: 24px;
}
.verifyAccount__form-lable {
  display: block;
  margin-bottom: 10px;
}
.inputHolder-ssn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.inputHolder-ssn-section {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.input-ssn {
  padding: 14px 16px;
  font-size: 14px;
  background-color: var(--grey10);
  border-radius: 8px;
  width: 40px;
  height: 52px;
}
.inputHolder-name {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.input-name {
  max-width: 275px;
  width: 100%;
  padding: 14px 16px;
  font-size: 16px;
  background-color: var(--grey10);
  border-radius: 8px;
}
.inputHolder-address,
.inputHolder-apt,
.inputHolder-location {
  margin-bottom: 20px;
}

.input-city,
.input-state {
  width: 100%;
}

.input-address,
.input-apt,
.input-zip,
.input-birtDate {
  width: 100%;
  padding: 14px 16px;
  font-size: 16px;
  background-color: var(--grey10);
  border-radius: 8px;
}
.inputHolder-location {
  display: flex;
  gap: 10px;
}
.inputHolder-location-citi-zip {
  display: flex;
  gap: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  order: -1; /* revers element */
  /*margin-left: 0px;  move icon */
  margin-right: 10px; /* margin icon */
}

.verifyAccount__form-buttonHolder {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

@media screen and (max-width: 1024px) {
  .verifyAccount__form .email__errorMassage {
    margin-top: 20px;
  }
  .verifyAccount__title {
    font-size: 28px;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .verifyAccount__subtitle{
    font-size: 16px;
    margin-bottom: 32px;
  }
  .errorIcon img{
    width: 50px;
  }
  .verifyAccount__form-buttonHolder {
    margin-top: 34px;
  }
  .errorIcon{
    margin-top: 60px;
  }
}

@media screen and (max-width: 743px) {
  .verifyAccount__form .email__errorMassage {
    margin-top: 16px;
  }
  .verifyAccount__title {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .verifyAccount__subtitle{
    font-size: 14px;
    margin-bottom: 24px;
  }
  .errorIcon img{
    width: 46px;
  }

  .inputHolder-name {
    flex-direction: column;
    gap: 10px;
  }
  .input-name {
    max-width: 100%;
  }
  .inputHolder-location {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .verifyAccount__form-buttonHolder {
    margin-top: 16px;
  }
  .errorIcon{
    margin-top: 40px;
  }
}

.ssnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 16px 8px 16px;
  border: 1px solid var(--grey10);
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  background-color: var(--grey10);
}

.ssnContainer:not(.error):has(input:focus) {
  border: 1px solid #39848a;
}

.ssnContainer__left,
.ssnContainer__center {
  padding-right: 40px;
}

@media screen and (max-width: 1024px) {
  .ssnContainer {
    margin-bottom: 20px;
  }
  .ssnContainer__left,
  .ssnContainer__center {
    padding-right: 20px;
  }
}

@media screen and (max-width: 743px) {
  .ssnContainer {
    margin-bottom: 16px;
  }
}

.ssnContainer.error {
  border: 1px solid #ff6666;
}

.ssnContainer__label.error {
  color: #ff6666;
}

.ssnContainer__label {
  position: absolute;
  top: 4px;
  font-size: 8px;
}

.ssnHiddenInput {
  width: 16px;
}