.round-button {
  width: 32px;
  height: 32px;
  border: solid #39848a;
  border-radius: 50%;
  background: #39848a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxCarousel {
  display: flex;
  justify-content: center;
}

.testimonials_btn {
  padding: 0 0 !important;
  width: 120px !important;
}

@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .testimonials_btn {
    padding: 0 40px !important;
    width: 120px !important;
  }
}
@media screen and (max-width: 743px) {
  .testimonials_btn {
    padding: 0 0 !important;
    width: 20px !important;
  }
}
