.contentCard {
  line-height: 16px !important;
  text-wrap: wrap !important;
  white-space: normal !important;
  word-break: normal !important;
  font-size: 12px !important;
  text-align: start !important;
  color: black !important;
}

.box_content_card {
  height: 90px !important;
  padding-block-end: 50px !important;
}

.testimonial_card {
  min-width: 278px !important;
  min-height: 140px !important;
  max-height: 140px !important;
  max-width: 278px !important;
  background-color: white !important;
  border: solid !important;
  border-radius: 10px !important;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .contentCard {
    line-height: 16px !important;
    text-wrap: wrap !important;
    white-space: normal !important;
    word-break: normal !important;
    font-size: 12px !important;
    text-align: start !important;
    color: black !important;
  }
  .testimonial_card {
    min-width: 278px !important;
    min-height: 140px !important;
    max-height: 140px !important;
    max-width: 278px !important;
    background-color: white !important;
    border: solid !important;
    border-radius: 10px !important;
  }
  .box_content_card {
    height: 90px !important;
    padding-block-end: 50px !important;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .contentCard {
    line-height: 16px !important;
    text-wrap: wrap !important;
    white-space: normal !important;
    word-break: normal !important;
    font-size: 12px !important;
    text-align: start !important;
    color: black !important;
  }
  .testimonial_card {
    min-width: 210px !important;
    min-height: 140px !important;
    max-height: 140px !important;
    max-width: 210px !important;
    background-color: white !important;
    border: solid !important;
    border-radius: 10px !important;
  }
  .box_content_card {
    height: 95px !important;
    padding-block-end: 50px !important;
  }
}

/* Mobile: */
@media screen and (max-width: 385px) {
  .contentCard {
    line-height: 16px !important;
    text-wrap: wrap !important;
    white-space: normal !important;
    word-break: normal !important;
    font-size: 12px !important;
    text-align: start !important;
    color: black !important;
  }
  .testimonial_card {
    min-width: 210px !important;
    min-height: 140px !important;
    max-height: 140px !important;
    max-width: 210px !important;
    background-color: white !important;
    border: solid !important;
    border-radius: 10px !important;
  }
  .box_content_card {
    height: 95px !important;
    padding-block-end: 50px !important;
  }
}
