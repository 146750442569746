.notificationBar {
  padding: 22px 16px;
  background-color: var(--grey10);
}

@media screen and (max-width: 1200px) {
  .notificationBar {
    padding: 16px 60px;
  }
}

@media screen and (max-width: 743px) {
  .notificationBar {
    padding: 16px 20px;
  }
}
.notificationBar__container {
  max-width: 1160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
}
.notificationBar__info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.notificationBar__info-text {
}
.notificationBar__info-icon img {
  min-width: 24px;
}

.hideBar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .notificationBar__button {
    min-width: fit-content;
  }
}
