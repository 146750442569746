.demo_user_form_container {
  width: 570px;
}
.signUpContainer {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.input__Holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}
.userName__errorMassage {
  text-align: center;
  color: var(--error);
  font-size: 18px;
  font-weight: 400;
}
.pass_with_errors {
  margin-block-end: 24px;
}
.haveAcc {
  font-size: 18px;
  text-align: center;
}
.have_acc_demo {
  margin-bottom: 0px;
}
.conditions {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 80px;
  cursor: default;
}

.button__holder_new_user {
  margin-block-end: 80px;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.customInput__holder {
  margin-block-end: 24px;
}
.username-error {
  margin-block-start: 0px !important;
}
.checkBoxSignUp {
  justify-content: center;
}
/* .sign-up-name-container {
    width: 570px;
  } */
.checkError {
  flex-direction: row;
  align-items: center;
  color: #f66 !important;
}
.passwordInputOnboarding {
  margin-block-end: 0;
}
.field_error_message {
  color: #f66;
  text-align: center;
  margin-block-start: 24px;
  font-size: 18px;
}
.login__link {
  margin-block-start: 12px;
}
.bttn_and_alreadyhaveacc {
  margin-block-start: 80px;
}

@media screen and (max-width: 1024px) {
  .bttn_and_alreadyhaveacc {
    margin-block-start: 60px;
  }
  .field_error_message {
    font-size: 16px;
    margin-block-start: 16px;
  }
  .pass_with_errors {
    margin-block-end: 16px;
  }
  .input__holder {
    gap: 10px;
  }
  .customInput__holder {
    margin-block-end: 16px;
  }

  .button__holder_new_user {
    margin-block-end: 60px;
  }

  .haveAcc {
    font-size: 16px;
  }

  .conditions {
    font-size: 16px;
    margin-bottom: 60px;
  }
  .username-error {
    margin-block-start: 0px !important;
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .bttn_and_alreadyhaveacc {
    margin-block-start: 0px;
  }
  .bttn_and_alreadyhaveacc {
    position: relative;
    bottom: -200px;
  }
  .field_error_message {
    font-size: 14px;
  }
  .pass_with_errors {
    margin-block-end: 16px;
  }
  .button__holder_new_user {
    margin-block-end: 0px;
    width: 100%;
    padding-block-end: 40px;
  }

  .haveAcc {
    font-size: 14px;
  }
  .input__holder {
    gap: 0;
    flex-direction: column;
  }

  .conditions {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .username-error {
    margin-block-start: 0px !important;
    font-size: 14px;
  }
}
