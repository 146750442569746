.banner_box {
  background-image: linear-gradient(to right, #00f38d, #39848a);
  width: 100%;
  min-height: 52px;
  height: 70px;
  align-content: center;
  position: sticky;
  display: flex;
  justify-content: center;
  font-family: "Open Sans";
  color: #303030;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  gap: 10px;
}

.banner_text_button {
  color: #303030 !important;
  font-size: 20px;
  font-weight: 700;
}

.banner_text_button:hover {
  text-decoration: underline;
}

.banner_text {
  display: flex;
  color: #303030;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .banner_box {
    gap: 0px;
    display: flex;
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    font-family: "Open Sans";
  }
  .banner_text {
    display: flex;
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
  }

  .banner_text_button {
    color: #303030;
    font-size: 18px;
    font-weight: 700;
  }
}

/* Mobile: */
@media screen and (max-width: 743px) {
  .banner_box {
    gap: 0px;
    display: flex;
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    font-family: "Open Sans";
  }
  .banner_text {
    display: flex;
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
  }

  .banner_text_button {
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    width: 320px;
    line-height: 24px;
  }
}

@media screen and (max-width: 462px) {
  .banner_box {
    display: flex;
    position: sticky;
    top: 0px;
    background-image: linear-gradient(to right, #00f38d, #39848a);
    width: 100%;
    min-height: 53px;
    align-items: center;
    padding-inline-start: 20px;
    padding-inline-end: 3px;
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    font-family: "Open Sans";
    justify-items: center;
    text-align: center;
  }

  .banner_text {
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
  }

  .banner_text_button {
    color: #303030;
    font-size: 16px;
    font-weight: 700;
  }
}
