.signupNavbar {
  margin-top: 40px;
}
.signupNavbar__control {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.backBtn__holder{
  min-height: 25px;
}
@media screen and (max-width: 1024px) {
  .signupNavbar {
    margin-top: 32px;
  }
  .signupNavbar__control {
    gap: 32px;
  }
}
@media screen and (max-width: 743px) {
  .signupNavbar {
    margin-top: 24px;
  }
  .signupNavbar__control {
    gap: 24px;
  }
}
