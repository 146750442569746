.banner_button_group {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 24px;
  margin-inline: 100px;
  text-align: center;
}


.container_banner_modal_color {
  background-color: rgba(112, 112, 112, 0.6);
  z-index: 1101;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.container_banner_modal {
  width: 560px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1101;
}

.terms_conditions_banner {
  font-size: 14px;
  text-align: start;
}

.close_button_banner_modal {
  position: relative;
  cursor: pointer;
}
.close_button_container {
  display: flex;
  justify-content: end;
}

.close_button_banner_modal_line {
  text-align: right;
}

.banner_box_title {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.banner__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  justify-content: center;
  color: var(--grey30);
}

.banner__description_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--grey30);
}

.numeration_description_text {
  padding-inline-start: 20px;
}

ol {
  list-style-type: decimal;
}

.banner_modal__content {
  width: 100%;
  max-width: 793px;
  padding: 20px 40px;
  border-radius: 12px;
  background: var(--secondary-black-color);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.banner_modal_button {
  background-image: linear-gradient(to right, #00f38d, #39848a);
  display: flex;
  justify-self: center;
  text-transform: none !important;
}

.banner_modal_link {
  text-decoration: underline !important;
}

.continue_btn_box {
  display: flex;
  justify-content: center;
}

.btn_box_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.interest_mainBtn {
  border: 1px;
  border-radius: 8px;
  background: linear-gradient(90deg, #4f886e 24.69%, #4e8389 75.94%), #39848a;
  width: 100%;
}

.mainBtn {
  border: 1px;
  border-radius: 8px;
  background-color: var(--primary-color) !important;
  width: 100%;
}

.secondaryBtn {
  border: 2px solid var(--primary-color) !important;
  border-radius: 8px;
  color: var(--primary-color) !important;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .banner_modal__content {
    width: 100%;
    padding: 20px 40px;
    border-radius: 12px;
    background: var(--secondary-black-color);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
    min-height: 300px; 
    justify-content: center; 
  }
  .banner__title {
    text-align: center;
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  .banner__description_text {
    font-size: 16px;
    line-height: 24px;
  }

  .numeration_description_text {
    padding-inline-start: 18px;
  }

  .banner_button_group {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 20px;
    text-align: center;
    margin-inline: 0;
  }
  .terms_conditions_banner {
    font-size: 14px;
    text-align: start;
  }
  .continue_btn_box {
    display: flex;
    justify-content: center;
  }
  .btn_box_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
}

@media screen and (max-width: 743px) {
  .banner_box_title {
    display: flex;
    gap: 2px;
  }
  .container_banner_modal_color {
    background-color: rgba(112, 112, 112, 0.6);
    position: none;
    width: 100%;
    height: 100%;
    bottom: 0px;
    z-index: 1101;
  }

  .banner_modal__content {
    width: 100%;
    max-width: 650px;
    padding: 20px 24px;
    border-radius: 12px 12px 0px 0px;
    background: var(--secondary-black-color);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .container_banner_modal {
    position: sticky;
    width: 100%;
    height: 100%;
    bottom: 0px;
    z-index: 1101;
    align-items: end;
    display: flex;
  }
  .banner__title {
    font-size: 24px;
    line-height: 32px;
    justify-content: center;
  }

  .banner__description_text {
    font-size: 14px;
    line-height: 20px;
  }

  .numeration_description_text {
    padding-inline-start: 15px;
  }

  .banner_button_group {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 16px;
    width: 100%;
    text-align: center;
  }
  .continue_btn_box {
    display: block;
    justify-content: initial;
  }
  .continue_btn {
    width: 100%;
  }
  .btn_box_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .terms_conditions_banner {
    font-size: 10px;
    text-align: start;
  }
}