.qaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
}
.containerBox {
  display: flex;
  gap: 12px !important;
}

.questionFaq {
  color: var(--Greyscale-Greyscale-10, #303030) !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.MuiTouchRipple-root {
  pointer-events: auto !important;
}

.openedQuestion {
  padding-block-end: 0px;
}

.icon {
  width: 16px;
  height: 12px;
  font-size: large;
  height: 50px;
  color: black;
}

.rotatedIcon {
  rotate: 180deg;
}

.questionContainer {
  display: flex;
  gap: 0.5rem;
}

.box_section_FAQ {
  padding-top: 80px;
}

.title_section_FAQ {
  color: var(--primary-color);
  font-size: 40px !important;
}

.answer_text {
  color: var(--Greyscale-Greyscale-10, #303030);
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: left !important;
}

.answer_text_link {
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: left !important;
}

.answer_bullet {
  padding-inline-start: 18px;
}

.answer_text_bold {
  font-weight: 700 !important;
}

.answer_text_link:hover {
  text-decoration: underline !important;
}

.whiteBox {
  background-color: #fafafa;
  color: #303030;
  padding-block: 80px !important;
}

.faq_subtitle {
  margin: 24px 0 0 0 !important;
  color: var(--Greyscale-Greyscale-40, #fafafa);
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.question_and_answer_section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
}

.link {
  font-weight: 700;
}

.link:hover {
  text-decoration: underline;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .whiteBox {
    background-color: #fafafa;
    color: #303030;
    padding-block: 70px !important;
  }
  .box_section_FAQ {
    padding-top: 70px;
  }

  .title_section_FAQ {
    color: var(--primary-color);
    font-size: 32px !important;
  }
  .answer_text_bold {
    font-weight: 700 !important;
  }
  .questionFaq {
    display: inline-block;
    text-align: start;

  }
  .containerBox {
    align-items: flex-start !important;
  }
  .icon {
    margin-block-start: 6px;
    height: 16px;
  }
}

/* Mobile: */
@media screen and (max-width: 743px) {
  .icon {
    margin-block-start: 5px;
    height: 16px;
  }
  .containerBox {
    align-items: flex-start !important;
  }
  .whiteBox {
    background-color: #fafafa;
    color: #303030;
    padding-block: 60px !important;
  }

  .box_section_FAQ {
    padding-top: 60px;
  }

  .title_section_FAQ {
    color: var(--primary-color);
    font-size: 24px !important;
  }

  .questionFaq {
    text-align: start;
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    text-transform: none !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  .answer_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
  }

  .answer_text_link {
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
  }

  .icon {
    font-size: large;
    color: black;
    display: flex;
    text-align: start;
    justify-content: center;
  }

  .rotatedIcon {
    rotate: 180deg;
  }

  .answer_text_bold {
    font-weight: 700 !important;
  }
}

/* Mobile: */
@media screen and (max-width: 385px) {
  .whiteBox {
    background-color: #fafafa;
    color: #303030;
    padding-block: 60px !important;
  }

  .title_section_FAQ {
    color: var(--primary-color);
    font-size: 24px !important;
  }

  .questionFaq {
    text-align: left;
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    text-transform: none !important;
    text-align: start;
  }

  .answer_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
  }

  .answer_text_link {
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
  }

  .icon {
    font-size: large;
    color: black;
  }

  .rotatedIcon {
    rotate: 180deg;
  }

  .answer_text_bold {
    font-weight: 700 !important;
  }
}
