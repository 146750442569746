.feedback__infoContainer {
  margin: 40px 0px;
  flex: 1;
}

.feedback__subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 24px;
}
.feedback__subtitle-short {
  font-size: 18px;
  text-align: center;
  line-height: 24px;
}
.success_automation_icon {
  margin: 40px 0;
  text-align: center;
}
.success_automation_icon img {
  width: 80px;
}
.feedback__subtitle-container {
  max-width: 650px;
  margin: 0 auto;
  cursor: default;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .success_automation_icon img {
    width: 60px;
  }
  .feedback__subtitle,
  .feedback__subtitle-short {
    font-size: 16px;
  }
}
@media screen and (max-width: 743px) {
  .success_automation_icon img {
    width: 40px;
  }
  .feedback__subtitle,
  .feedback__subtitle-short {
    font-size: 14px;
    line-height: 20px;
  }
}
