.stepper {
  max-width: 765px;
  width: 100%;
  margin: 0 auto;
  cursor: default;
}
.stepper__list {
  display: flex;
  justify-content: space-between;
}
.stepper__list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
}
.stepper__list-item-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--grey10);
  color: var(--grey30);
}
.stepper__list>:nth-child(1).stepper__list-item>.stepper__list-item-number {
  background-color: var( --primary-color);
  color: var(--grey40);
}
.stepper__list-item-number.step-done {
  background-color: var(--grey20) !important;
}


.stepper__list-item-title{
  font-size: 18px;
  color: var(--grey20);
  margin-top: 10px;
  text-align: center;
}
.stepDevider{
  max-width: 56px;
  width: 100%;
  overflow: hidden;
  margin: 0px 10px;
  padding-top: 20px;
  font-weight: 700;
  color: var(--grey30);
  letter-spacing: -5px;
}
@media screen and (max-width: 1024px) {
  .stepDevider {
    max-width: 32px;
  }
  .stepper__list-item-title{
   font-size: 16px;
  } 
}

@media screen and (max-width: 743px) {
  .stepper__list-item-title{
   font-size: 14px;
  } 
}
