.wrapUpTitle {
  align-self: center;
  margin-block-start: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
}
.lottie_animation {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .wrapUpTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-block-start: 80px;
  }
}
@media screen and (max-width: 743px) {
  .wrapUpTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    margin-block-start: 120px;
    width: 240px;
  }
}
