input[name="signUpEmail"]:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}
.email__errorMassage {
  text-align: center;
  color: var(--error);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.error__message {
  margin-bottom: 24px;
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .email__errorMassage {
    font-size: 16px;
  }

  .error__message {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .email__errorMassage {
    font-size: 14px;
  }

  .error__message {
    margin-bottom: 16px;
  }
}
