input[name="userAddress"]:-webkit-autofill,
input[name="userApartment"]:-webkit-autofill,
input[name="userCity"]:-webkit-autofill,
input[name="userZipCode"]:-webkit-autofill,
input[name="userState"]:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}
.autocomplete-dropdown-container {
  margin-top: -4px;
  z-index: 9;
  position: absolute;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #303030;
}

.autocomplete-dropdown-container::after {
  content: ' ';
  padding-bottom: 47px;
}

.cant-find-location {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  background-color: #303030;
}

.addressSearchIcon {
  position: absolute;
  right: 20px;
  top: 18px;
}

.inputHolder {
  margin: 0;
  position: relative;
}

.location-citi-zip {
  display: flex;
  gap: 8px;
}
.location-citi,
.location-zip {
  flex: 1;
}

.suggestion-item {
  cursor: pointer;
  padding: 16px 24px;
}

.suggestion-item:hover {
  background-color: #707070;
}

.inputHolder .customInput {
  padding: 20px 42px 14px 16px;
}

.address .customInput__holder {
  max-width: 100%;
}

.backToAddressSearchIcon {
  position: absolute;
  z-index: 9;
  right: 16px;
  font-size: 30px!important;
  top: 12px;
  cursor: pointer;
}

/* MEDIA */
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 743px) {
  .location-zip {
    max-width: 100px;
  }
}
