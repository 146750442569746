.question {
  display: flex;
  text-align: start;
  gap: 8px;
  font-size: 18px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  user-select: none;
  align-items: center;
}
.questionCheckbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -12;
  -webkit-appearance: none;
  appearance: none;
}
.questionCheckbox__input:checked + .checkbox__custom {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.questionCheckbox__input:checked + .checkbox__custom::before {
  transform: translate(-50%, -50%) scale(1);
}
.checkbox__custom {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--secondary-black-color);
  border: 2px solid var(--grey20);
  border-radius: 4px;
  position: relative;
  min-width: 24px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.checkbox__custom::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.2s ease-in-out;
  pointer-events: none;
}

@media (hover: none) {
  .question {
    -webkit-tap-highlight-color: transparent;
  }

  .checkbox__custom {
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .question {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .question {
    font-size: 14px;
  }
}
