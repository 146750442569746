.hide {
  display: none;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-black-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  /* opacity: 0.5; */
}
.loader__logo {
  max-width: 1180px;
  width: 100%;
  padding: 16px 20px;
}

.loader__logo img {
  padding: 8px;
}
.loader__svgHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.loader svg {
  position: relative;
  width: 80px;
  height: 80px;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  color: var(--grey30);
  text-align: center;
  padding: 0 10px;
}

.loader svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  stroke: var(--primary-color);
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 220;
  stroke-dashoffset: 220;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    stroke-dashoffset: 440;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 880;
  }
  100% {
    stroke-dashoffset: 440;
  }
}
@media screen and (max-width: 743px) {
    .loader-title {
        font-size: 18px;
    }
}
