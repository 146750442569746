.smr__text {
    margin: 40px 0;
}

.smr__infoIcon {
    margin-bottom: -5px;
    margin-left: 5px;
}

.smr {
    max-width: 427px;
    margin: 0 auto;
}

.smr__img {
    height: 236px;
}

.saveMoneyCalculation.MuiTooltip-tooltip {
    width: 308px;
}

@media screen and (max-width: 1024px) {
    .smr {
        max-width: initial;
    }

    .smr__img {
        height: auto;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 744px)  {
    .smr__title {
        margin-top: 50px!important;
    }

    .smr__text {
        margin: 24px 0 40px;
    }
}

@media screen and (max-width: 743px) {
    .saveMoneyCalculation.MuiTooltip-tooltip {
        width: 232px;
    }
    .smr__title {
        font-size: 18px!important;
        line-height: 20px!important;
        margin-top: 40px!important;
    }

    .smr__text {
        margin: 16px 0 24px;
    }
}