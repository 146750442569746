.customPhoneInput__holder_with_error {
  border: 1.2px solid #e2173e;
  border-radius: 8px;
  margin: 0 auto;
  width: 50%;
}
.phonenumber_input {
  width: 50%;
  margin: 0 auto;
}
.phone__form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.phone_form_subtitle {
  width: 80%;
}

@media screen and (max-width: 1024px) {
  .demo_form_error {
    font-size: 16px !important;
  }
  .phonenumber_input {
    width: 95%;
  }
  .customPhoneInput__holder_with_error {
    width: 95%;
  }
  .phone_form_subtitle {
    width: 92%;
  }
}
@media screen and (max-width: 743px) {
  .demo_form_error {
    font-size: 14px !important;
  }
  .customPhoneInput__holder_with_error {
    width: 100%;
  }
  .phonenumber_input {
    width: 100%;
  }
}
