.balanceInput__container {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  .balanceInput__container .MuiInputLabel-root.Mui-focused {
    transform: translate(12px, 7px) scale(1);
    -webkit-transform: translate(12px, 7px) scale(1);
    -moz-transform: translate(12px, 7px) scale(1);
    -ms-transform: translate(12px, 7px) scale(1);
  }
  /*New Balance input styles behavior for edit automation*/
  .edit-automation__container .balanceInput__container .MuiInputBase-root.MuiFilledInput-root {
    border: 0;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputBase-root.MuiFilledInput-root.Mui-focused {
    border: 1px solid #39848A;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputBase-root.MuiFilledInput-root.Mui-error {
    border: 1px solid #F66;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputBase-root.MuiFilledInput-root.Mui-error.Mui-focused {
    border: 1px solid #F66;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputLabel-shrink {
    color: #FAFAFA;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputLabel-shrink.Mui-focused {
    color: #39848A;
  }
  
  .edit-automation__container .balanceInput__container .MuiInputLabel-shrink.Mui-error.Mui-focused {
    color: #F66;
  }
  /**/
  
  .disabled {
    opacity: 0.5;
  }
  
  .balance__input {
    width: 100%;
    height: 52px;
    padding: 16px;
    margin: 0 auto;
    background-color: var(--grey10);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
  }
  .balance__input-edit{
  border: none;
  }
  
  .dollar {
    position: absolute;
    top: 19px;
    left: 16px;
    visibility: hidden;
  }
  .inputError {
    border: 1px solid #ff6666 !important;
  }
  .balanceInput__label {
    position: absolute;
    font-size: 14px;
    top: 16px;
    left: 16px;
    transition: all 0.5s ease;
  }
  .labelError,
  .dolarError {
    color:var(--grey40);
  }
  .balance__input:focus {
    border: 3px solid var(--primary-color);
  }
  .balance__input:focus,
  .balance__input:valid {
    padding: 16px 16px 8px 26px;
  }
  .balance__input:focus ~ .dollar,
  .balance__input:valid ~ .dollar {
    visibility: visible;
  }
  .balance__input:focus ~ .balanceInput__label,
  .balance__input:valid ~ .balanceInput__label,
  .balance__input:read-only ~ .balanceInput__label {
    font-size: 8px;
    transform: translateY(-10px);
  }
  
  
  .balance__input:disabled ~ .balanceInput__label {
    position: absolute;
    font-size: 14px;
    top: 25px;
    left: 16px;
  }
  .balance__input:disabled{
    border: none;
  }
  
  
  .balanbalance__inputceInput:-webkit-autofill,
  .balance__input:-webkit-autofill:hover,
  .balance__input:-webkit-autofill:focus,
  .balance__input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 40px 40px var(--grey10);
  }
  
  /* MEDIA */
  