input[name="userPassword"]:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}
.passwordInput {
  max-width: 570px;
  width: 100%;
  margin: 0 auto 24px;
  color: var(--grey30);
  cursor: default;
}

.strengBar {
  margin-top: 17px;
}
@media screen and (max-width: 1024px) {
  .passwordInput {
    max-width: 624px;
    margin: 0 auto 20px;
  }
}
@media screen and (max-width: 743px) {
  .passwordInput {
    margin: 0 auto 16px;
  }
}
.inputBox {
  position: relative;
  max-width: 570px;
  width: 100%;
  margin: 0px auto;
}
.inputBox input {
  display: block;
  max-width: 570px;
  width: 100%;
  background-color: var(--grey10);
  padding: 16px;
  border-radius: 8px;
  margin: 10px auto;
}
.passwordInput__error input {
  border: 1px solid var(--error);
}
.inputBox input::placeholder {
  color: var(--grey30);
}
.eye_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 30px;
}

.eye_icon svg {
  color: var(--grey30);
}
.error svg {
  color: var(--error);
}

.inputBox__requirements {
  color: var(--grey30);
  font-size: 14px;
}
.password__conditions-title {
  font-size: 18px;
  margin: 16px 0px 8px 0;
}
.password__condition-item {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}
.condition-description {
  font-size: 18px;
}

/* change Srang Bar style  */
.strengBar > div {
  height: 20px;
}
.strengBar > div :nth-child(1) {
  height: 6px !important;
  border-radius: 5px;
}
.strengBar > div :nth-child(3) {
  height: 6px !important;
  border-radius: 5px;
}
.strengBar > div :nth-child(5) {
  height: 6px !important;
  border-radius: 5px;
}
.strengBar > div :nth-child(7) {
  height: 6px !important;
  border-radius: 5px;
}
.strengBar > p {
  color: var(--primary-color) !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px  !important;
}
/* end */
@media screen and (max-width: 1024px) {
  .condition-description,
  .password__conditions-title {
    font-size: 16px;
  }
  .strengBar > p {
    font-size: 14px  !important;
  }
}
@media screen and (max-width: 743px) {
  .condition-description,
  .password__conditions-title {
    font-size: 14px;
  }
  .strengBar > p {
    font-size: 12px  !important;
  }
}
