.page__infoContainer.centered {
  flex: 1 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unableVerify {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.unableVerify__infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.unableVerify__infoContainer-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
}
.unableVerify__infoContainer-subtitle {
  color: var(--grey30);
  font-size: 18px;
  text-align: center;
}
.error__icon-holder{
  width: 80px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
  .error__icon-holder{
    width: 60px;
  }
  .unableVerify__infoContainer-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .unableVerify__infoContainer-subtitle {
    font-size: 16px;
  }
}
@media screen and (max-width: 743px) {
  .error__icon-holder{
    width: 56px;
  }
  .unableVerify__infoContainer-title {
    font-size: 24px;
    margin-bottom: 16px;

  }
  .unableVerify__infoContainer-subtitle {
    font-size: 14px;
  }
}
