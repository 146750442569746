.login__infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
.login__inputHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}
.signup__link {
  text-align: center;
  margin-bottom: 60px;
  font-size: 18px;
}
.signup__link a {
  color: var(--primary-color);
}
.login__errorMassage {
  display: block;
  color: var(--error);
  width: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 12px 0 14px 0;
}
.forgotPassword {
  font-size: 18px;
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 1024px) {
  .signup__link {
    margin-bottom: 40px;
    font-size: 16px;
  }
  .forgotPassword {
    margin-bottom: 60px;
    font-size: 16px;
  }
  .login__errorMassage {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .forgotPassword {
    margin-bottom: 40px;
    font-size: 14px;
  }
  .signup__link {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .login__errorMassage {
    font-size: 14px;
  }
}
