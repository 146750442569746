/* Desktop: */

.layout_post {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: start;
  padding-block-end: 5px;
}

.blog_main_link {
  color: var(--Primary, #39848a);
  font-family: "Open Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.layout_title_post {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title_post {
  color: #000;
  font-family: "Open Sans";
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}

.author_name_post {
  color: var(--Greyscale-Greyscale-10, #303030);
  font-family: "Open Sans";
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px;
}

.image_block_post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.image_layout_post {
  display: flex;
  justify-content: center;
}

.image_post {
  width: 500px;
}

.image_copyright{
  color: var(--Greyscale-Greyscale-10, #707070);
  display: flex;
  justify-content: center;
}

.text_post {
  color: var(--Greyscale-Greyscale-10, #303030);
  font-family: "Open Sans";
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.layout_recommended_title {
  width: 362px;
}

.recommended_title_post {
  color: var(--Greyscale-Greyscale-10, #303030);
  font-family: Open Sans;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  text-align: left;
}

.recommended_title_post:hover {
  cursor: pointer;
}
.layout_recommended_posts {
  display: flex;
  gap: 65px;
}

.blog_post_conent ul li {
  list-style: disc;
  margin-inline-start: 50px;
  margin-inline-end: 0px;
}

.post_content_underline {
  text-decoration: underline;
}

.recommended_image_post {
  width: 374px;
}

.recommended_post_link_card {
  color: var(--Primary, #39848a);
  text-align: right;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.newletter_text {
  color: var(--Greyscale-Greyscale-10, #303030);
  font-family: "Open Sans";
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: center;
  padding-inline: 290px;
  padding-block-end: 10px;
}

.subscribe_error {
  color: var(--Greyscale-Greyscale-10, #ff0000);
  font-family: "Open Sans";
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: left;
}

.layout_email_newsletter_input {
  width: 500px;
}

.email_newsletter_text_field {
  color: var(--Greyscale-Greyscale-40, #fafafa) !important;
  text-align: center !important;
  font-family: "Open Sans" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.layout_email_newsletter_input ::placeholder {
  color: var(--grey30) !important;
  font-family: "Open Sans" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 24px !important;
  text-align: left;
}
.email_newsletter_text_field
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

.layout_newsletter_input {
  display: flex;
  justify-content: center;
  height: 56px;
  border-radius: 8px;
}

.layout_background_email_newsletter {
  background-color: #303030;
  display: flex;
  border-radius: 8px;
}

.newsLetter_button {
  color: white !important;
}
.newsLetter_button:hover {
  color: var(--Primary, #40949a) !important;
}

.layout_social_logos {
  display: flex;
  justify-content: center;
}
.social_logos {
  width: 500px;
  display: flex;
  gap: 6px;
  justify-content: end;
}

.social_logo {
  width: 40px;
  height: 40px;
}

.email_logo {
  width: 45px;
  height: 44px;
}
@media only screen and (max-width: 1204px) and (min-width: 1024px) {
  .layout_recommended_posts {
    display: flex;
    flex-direction: column;
    gap: 65px;
  }
  .layout_post {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .recommended_title_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: Open Sans;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    text-align: left;
    width: 900px;
  }
  .newletter_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    text-align: center;
    padding-inline: 120px;
    padding-block-end: 10px;
  }
}
/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .layout_recommended_posts {
    display: flex;
    flex-direction: column;
    gap: 65px;
    justify-content: center;
  }
  .layout_post {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .email_newsletter_input {
    width: 600px;
  }

  .layout_newsletter_input {
    display: flex;
    justify-content: center;
    height: 53px;
    border-radius: 8px;
  }

  .layout_background_email_newsletter {
    background: var(--Greyscale-Greyscale-10, #303030);
    display: flex;
    border-radius: 8px;
  }

  .blog_main_link {
    color: var(--Primary, #39848a);
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  .title_post {
    color: #000;
    font-family: "Open Sans";
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  .author_name_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px;
  }
  .blog_post_conent ul li {
    list-style: disc;
    margin-inline-start: 50px;
    margin-inline-end: 0px;
  }
  .recommended_title_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: Open Sans;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    text-align: left;
    width: 744px;
  }
  .recommended_post_link_card {
    color: var(--Primary, #39848a);
    text-align: right;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .newletter_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    text-align: center;
    padding-inline: 120px;
    padding-block-end: 10px;
  }
  .recommended_title_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: Open Sans;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left;
  }
  .recommended_post_link_card {
    color: var(--Primary, #39848a);
    text-align: right;
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .recommended_card {
    padding: 20px;
    padding-block-end: 5px;
  }
  .layout_post {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .blog_main_link {
    color: var(--Primary, #39848a);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  .title_post {
    color: #000;
    font-family: "Open Sans";
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  .image_post {
    width: 375px;
  }
  .author_name_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px;
  }
  .social_logos {
    width: 375px;
    display: flex;
    gap: 6px;
    justify-content: end;
  }
  .newletter_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    padding-inline: 0px;
    padding-block-end: 10px;
  }
  .blog_post_conent ul li {
    list-style: disc;
    margin-inline-start: 30px;
    margin-inline-end: 0px;
  }
  .layout_recommended_posts {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .recommended_image_post {
    width: 385px;
  }
  .layout_recommended_title {
    width: 350px;
  }
  .recommended_title_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: Open Sans;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-align: left;
  }
  .recommended_post_link_card {
    color: var(--Primary, #39848a);
    text-align: right;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .layout_email_newsletter_input ::placeholder {
    color: var(--Greyscale-Greyscale-40, #fafafa) !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 20px !important;
    text-align: left;
  }
  .email_newsletter_input {
    width: 600px;
  }

  .layout_newsletter_input {
    display: flex;
    height: 56px;
    justify-items: center !important;
    border-radius: 8px;
  }

  .layout_background_email_newsletter {
    background: var(--Greyscale-Greyscale-10, #303030);
    display: flex;
    border-radius: 8px;
  }
  .social_logo {
    width: 20px;
    height: 20px;
  }

  .email_logo {
    width: 23px;
    height: 23px;
  }
}

/* Mobile: */
@media screen and (max-width: 385px) {
  .layout_post {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .blog_main_link {
    color: var(--Primary, #39848a);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  .title_post {
    color: #000;
    font-family: "Open Sans";
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  .image_post {
    width: 250px;
  }
  .author_name_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px;
  }
  .social_logos {
    width: 250px;
    display: flex;
    gap: 6px;
    justify-content: end;
  }
  .newletter_text {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    padding-inline: 0px;
    padding-block-end: 10px;
  }
  .layout_recommended_posts {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .recommended_image_post {
    width: 250px;
  }
  .layout_recommended_title {
    padding-block-start: 10px;
    width: 250px;
  }
  .recommended_title_post {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: Open Sans;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-align: left;
  }
  .recommended_post_link_card {
    color: var(--Primary, #39848a);
    text-align: right;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .email_newsletter_input {
    width: 600px;
  }

  .layout_newsletter_input {
    display: flex;
    height: 50px;
    border-radius: 8px;
  }

  .newsLetter_button {
    color: white !important;
    width: 100px;
  }

  .layout_background_email_newsletter {
    background: var(--Greyscale-Greyscale-10, #303030);
    display: flex;
    border-radius: 8px;
  }

  .layout_email_newsletter_input ::placeholder {
    color: var(--Greyscale-Greyscale-40, #fafafa) !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 20px !important;
    text-align: left;
  }
}
