.credit_data_choose_accs {
  display: flex;
  gap: 32px;
}
.bankCard__balance {
  color: var(--Greyscale-Greyscale-40, #fafafa);
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.bankCard__bankLogo {
  display: flex;
  align-items: center;
}
.demo_creditCard {
  margin: 0px;
}

.demo_bank_card_num {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (max-width: 743px) {
  .credit_data_choose_accs {
    gap: 10px;
  }
  .demo_bank_card_num {
    font-size: 16px;
  }
  .demo_bankCard__balance {
    font-size: 24px;
  }
}
