.page__title-width {
  max-width: 765px;
  margin: 0 auto;
}
.ssn__input__holder {
  max-width: 374px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.ssn__input__holder .otp-input-container {
  margin: 0 auto 24px;
}

@media screen and (max-width: 1024px) {
  .ssn__input__holder .otp-input-container {
    margin: 0 auto 20px;
  }
}
@media screen and (max-width: 743px) {
  .ssn__input__holder .otp-input-container {
    margin: 0 auto 16px;
  }
}

.ssn__input {
  display: block;
  max-width: 72px;
  height: 80px;
  width: 100%;
  background-color: var(--grey10);
  padding: 16px;
  border-radius: 8px;
  margin: 0px auto;
  text-align: center;
  border: 1px solid var(--primary-color);
}
.ssnInput__error {
  border: 1px solid var(--error);
}
.ssn__error {
  text-align: center;
  font-size: 18px;
}
.ssn__input::placeholder {
  color: var(--grey30);
}
/* .ssn__input:focus{
    border: 1px solid var(--primary-color);
  } */

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .page__title-width {
    width: 100%;
  }
}
@media screen and (max-width: 743px) {
  .page__title-width {
    width: 100%;
  }
}
