.bankCards_container {
  border: 1px solid #707070;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-inline: 32px;
  border-radius: 16px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.container_choose_forspacings {
  height: 550px;
}
.nonSelectedAccount {
  border: 1px solid transparent;
}
.selectedAccount {
  margin-bottom: 16px;

  border: 1px solid #39848a;
}
.choose_accounts_next_button {
  position: fixed;
  bottom: 80px;
  display: flex;
  justify-content: center;
}

.bankCards_container_with_error {
  border: 1.2px solid #e2173e;
  border-radius: 16px;
}
.nonSelectedAccount {
  margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
  .choose_accounts_next_button {
    bottom: 60px;
  }
  .container_choose_forspacings {
    margin-block-end: 0px;
  }
}
@media screen and (max-width: 743px) {
  .choose_accounts_next_button {
    bottom: 40px;
  }
}
