.bankCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: 16px;
  margin: 0 0 16px 0;
  cursor: pointer;
  position: relative;
  color: var(--grey30);
  background-color: var(--grey10);
}
.bankCard-used {
  cursor: default;
  border: 1px solid var(--grey10);
  color: var(--grey10);
  opacity: 0.2;
}
.bankCard__bankName {
  font-size: 16px;
  font-weight: 400;
  color: var(--grey40);
  margin-bottom: 10px;
}
.bankCard__number {
  font-size: 20px;
  font-weight: 700;
  color: var(--grey40);
}

.bankCard__bankLogo img {
  width: 60px;
}
@media screen and (max-width: 1024px) {
  .bankCard__bankLogo img {
    width: 50px;
  }
}
@media screen and (max-width: 743px) {
  .bankCard__bankLogo img {
    width: 40px;
  }
  .bankCard__bankName {
    font-size: 14px;
  }
  .bankCard__number {
    font-size: 18px;
  }
}
