.containerDeleteAutomationModal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(112, 112, 112, 0.6);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal__content {
  width: 100%;
  max-width: 650px;
  padding: 40px;
  border-radius: 12px;
  background: var(--secondary-black-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.circleSVG {
  position: absolute;
  width: 66.67px;
  height: 66.67px;
  border-radius: 50%;
  top: 47px;
}

.deleteAutomation__title {
  margin-top: 24px;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: var(--grey30);
}
.deleteAutomation__questionsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 24px;
}

.deleteAutomation__description.fixed {
  margin-top: 24px;
}

.deleteAutomation__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--grey30);
  margin-top: 36px;
}
.deleteAutomation__label {
  display: flex;
  gap: 10px;
}

.modalButtonHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-top: 24px;
}

@media screen and (max-width: 1024px) {
  .modalButtonHolder {
    padding-top: 20px;
  }
}

@media screen and (max-width: 743px) {
  .modalButtonHolder {
    padding-top: 16px;
  }
}

.deleteAutomation__question {
  margin-bottom: 24px;
}

.delete__textarea {
  margin-top: 24px;
  width: 100%;
  min-height: 254px;
  padding: 14px 16px;
  resize: none;
  border-radius: 14px;
  background-color: var(--grey10);
  font-size: 18px;
}
.delete__textarea::placeholder {
  color: var(--grey40);
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .deleteAutomation__title {
    font-size: 28px;
    margin-top: 20px;
  }
  .deleteAutomation__description {
    font-size: 16px;
    margin-top: 32px;
    line-height: 24px;
  }
  .delete__textarea {
    margin-top: 20px;
  }
}

@media screen and (max-width: 743px) {
  .modal__content {
    padding: 24px;
  }
  .deleteAutomation__title {
    font-size: 24px;
    margin-top: 16px;
  }

  .deleteAutomation__description {
    font-size: 14px;
    margin-top: 24px;
    line-height: 20px;
  }

  .deleteAutomation__label {
    font-size: 14px;
  }

  .modalButtonHolder {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .delete__textarea {
    margin-top: 16px;
  }
}
