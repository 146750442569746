/* Desktop: */

.layout_post_card {
  display: flex;
  gap: 20px;
}
.title_post_card {
  color: var(--primary-color) !important;
  font-family: Open Sans;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  text-align: left;
}

.title_post_card:hover {
  cursor: pointer;
}

.author_name_card {
  color: var(--Greyscale-Greyscale-10, #303030) !important;
  font-family: Open Sans;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  text-align: left;
  padding-block-start: 24px;
  padding-block-end: 36px;
}

.author_name_card:hover {
  cursor: pointer;
}

.image_post_card {
  width: 350px;
  gap: 0px;
}

.description_post_card {
  padding-inline: 10px;
  padding-block: 20px;
  word-wrap: break-word;
  text-align: left !important;
  color: var(--Greyscale-Greyscale-10, #303030) !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.description_post_card:hover {
  cursor: pointer;
}

.post_link_card {
  color: var(--Primary, #39848a);
  text-align: right;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.layout_post_link_card {
  display: flex;
  justify-content: end;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .title_post_card {
    color: var(--primary-color);
    font-family: Open Sans;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left;
  }
  .layout_post_card {
    display: flex;
    gap: 20px;
  }
  .author_name_card {
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: Open Sans;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    text-align: left;
    padding-block-start: 20px;
    padding-block-end: 24px;
  }
  .image_post_card {
    width: 274px;
    gap: 0px;
  }
  .description_post_card {
    padding-inline: 10px;
    padding-block: 20px;
    word-wrap: break-word !important;
    text-align: left !important;
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .layout_post_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .title_post_card {
    color: var(--primary-color);
    font-family: Open Sans;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-align: left;
  }
  .author_name_card {
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: Open Sans;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    text-align: left;
    padding-block-start: 18px;
    padding-block-end: 20px;
  }
  .image_post_card {
    width: 385px;
    gap: 0px;
  }
  .description_post_card {
    padding-inline: 0px;
    padding-block-start: 0px;
    padding-block-end: 0px;
    word-wrap: break-word;
    text-align: left !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
  }
  .post_link_card {
    font-size: 18px;
    line-height: 22px;
  }
}

/* Mobile: */
@media screen and (max-width: 385px) {
  .layout_post_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .title_post_card {
    color: var(--primary-color);
    font-family: Open Sans;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-align: left;
  }
  .author_name_card {
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: Open Sans;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    text-align: left;
    padding-block-start: 18px;
    padding-block-end: 20px;
  }
  .image_post_card {
    width: 250px !important;
    padding-inline-start: 20px;
    gap: 0px;
  }
  .description_post_card {
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    padding-inline-end: 5px;
    margin-inline: 20px !important;
    word-wrap: break-word;
    text-align: left !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
}
