.demo_bttn {
  text-align: center;
  /* position: fixed; */
  left: 0;
}

@media (min-width: 1024px) {
  /* Desktop screens */
  .demo_bttn {
    margin-top: 80px;
    bottom: auto; /* Not fixed to the bottom */
    width: 100%;
  }
}

@media (max-width: 1023px) and (min-width: 743px) {
  /* Tablet screens */
  .demo_bttn {
    margin-top: 60px;
    bottom: auto; /* Not fixed to the bottom */
    width: 100%;
  }
}

@media (max-width: 742px) {
  /* Phone screens */
  .demo_bttn {
    margin: 0;
    bottom: 20px;
    width: 90%;
    margin-block-start: 130px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
}
