.table__container {
  margin-top: 80px;
}
.table__title {
  font-size: 32px;
}
.table__holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  width: 100%;
  align-self: stretch;
  border-radius: 16px;
}
.payments_row_table {
  padding: 10px 0 !important;
}
.header__row__table {
  border-bottom: 1px solid rgba(48, 48, 48, 1);
  /* padding: 16px 0px; */
  color: var(--grey30);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 16px;
}

.row__table {
  color: var(--grey30);
  font-size: 16px;
  padding: 24px 0;
  border-bottom: 1px solid rgba(48, 48, 48, 1);
}

.row__table,
.header__row,
.header__row__table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}

.row__table > span,
.header__row__table > span {
  width: 25%;
  width: 100%;
}

.table__data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  align-self: stretch;
  width: 100%;
}

.sort__data {
  .active {
    border-radius: 4px;
    color: var(--grey30);
    background: var(--primary-color);
  }
}

.account__chart {
  display: flex;
  width: 100%;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--grey20);
}

.account__holder__header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.account__balance__title {
  display: flex;
  max-width: 420px;
  align-items: flex-start;
  gap: 20px;
  color: var(--grey40);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tableBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
}

.middleBlock__title {
  display: none;
  align-items: flex-start;
  color: var(--grey40);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.total_transfer_top {
  color: var(--grey30);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.total_transfer_bottom {
  color: var(--grey30);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header__table__filter {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 5px;
}

.status__icon {
  margin-right: 4px;
}
.row_icon {
  display: flex;
  align-items: center;
}

.viewMore {
  color: var(--primary-color);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: underline !important;
}


/* MEDIA */
@media screen and (max-width: 1024px) {
  .row__table {
    padding: 20px 0;
  }
  .table__container {
    margin-top: 60px;
  }

  .table__title {
    font-size: 28px;
  }
  .account__backButton {
    max-width: 644px;
  }

  .header__row__table,
  .row__table {
    font-size: 14px;
  }

  .headerBlock {
    margin-bottom: -60px;
  }

  .account__chart {
    padding: 16px;
  }

  .black__title__small {
    color: var(--mainFontColor);
    font-weight: 700;
  }

  .header__row__table {
    font-weight: 600;
  }

  .table__data {
    font-size: 12px;
  }

  /* .row__table > span,
    .header__row__table > span {
      width: 50px;
    } */

  /* .row__table > span:first-child,
    .header__row__table > span:first-child {
      width: 120px;
    } */
  /* NEW */
}

@media screen and (max-width: 743px) {
  .row__table {
    padding: 16px 0;
  }
  .table__container {
    margin-top: 40px;
  }
  .table__title {
    font-size: 24px;
  }
  .header__row__table,
  .row__table {
    font-size: 12px;
  }

  .account__backButton {
    max-width: 370px;
  }

  .account__top__description {
    flex-direction: column;
    align-items: flex-start;
  }

  .account__bottom__description {
    flex-wrap: wrap;
  }

  .account__right__description {
    padding-top: 16px;
    margin-left: auto;
  }

  .right__description {
    width: 100%;
    margin-top: 32px;
  }

  .account__description {
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
