.automations__container {
  display: flex;
  max-width: 1180px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background: var(--secondary-black-color);
  padding: 0px 20px 40px 20px;
  margin-top: 80px;
}
.dashboard__infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px;
}
.bannerModalDashboard {
  display: flex;
  justify-content: center;
}

.allSetSubTitle {
  text-align: center;
}
.footerText {
  display: flex;
  align-self: center;
  width: 710px;
  font-size: 16px;
}

.automations__headerBlock {
  /* width: 100%;
  position: absolute;
  top: 0;
  left: 0; */
}

.automations__header__column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.automations__header__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addAutomation__container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--grey10);
}

.addAutomation__holder {
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 20px;
  max-width: 1180px;
  margin: 0 auto;
}

.addAutomation__title {
  color: var(--grey30);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}

.automations__header__leftColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.automations__header__rightColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  flex-shrink: 0;

  .automations__header__bottom > span {
    color: var(--grey30);
    font-size: 24px;
    font-weight: 700;
  }
}

.automations__header__top {
  color: var(--grey30);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.automations__header__balance {
  color: var(--grey30);
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.automations__header__bottom {
  color: var(--grey30);
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.automations__header__chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.sort__data {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
}

.sort__data > li {
  list-style-type: none;
}

.sort__data {
  .active {
    border-radius: 4px;
    color: var(--grey30);
    background: var(--primary-color);
  }
}

.viewMoreBlock {
  margin-top: 24px;
  text-align: center;
}

.automations__table__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.automationsBlock {
  /* display: flex; */
  width: 100%;
  /* flex-direction: column;
  align-items: flex-start; */
  gap: 40px;
}

.automationsBlock__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.automationsBlock__title {
  color: var(--mainFontColor);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.automations__cardsHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  /* flex-wrap: wrap; */
}
.automations__checkingCards {
  max-width: 540px;
  width: 100%;
  border-radius: 16px;
}
.automations__savingsCards {
  max-width: 540px;
  width: 100%;
}

.automations__cardsDetails {
  text-align: right;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 24px;
  text-decoration: underline;
}

.automation__fixedValue {
  background-color: var(--grey10);
  width: fit-content;
  font-weight: 400;
  text-align: left;
  padding: 8px 45px 8px 16px;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 24px;
}

.automation__fixedValue-title {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.automations__cardsContainer {
  display: flex;
  width: 100%;
  /* padding: 32px; */
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
}

.cardsBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}

.automations__card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--grey20);
  height: max-content;
  max-width: 540px;
  width: 100%;
}

.automations__line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: #707070;
  width: 100%;
  height: 1px;
}

.automations__card-title {
  font-weight: 700;
}

.automations__card-infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.automations__card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.automations__card-info-account {
  font-weight: 400;
}

.automations__card-info-balance {
  font-size: 12px;
  font-weight: 400;
  color: var(--grey20);
}

.automations__card-info-value {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
}

.automations__card-info-bankLogo {
  padding: 8px;
  background-color: #f3f5f7;
  border-radius: 50%;
}

.automations__cardsDivider {
  display: flex;
  flex-direction: column;
  padding: 5px 9px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.automations__cardsDivider-value {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.automations__cardsDivider-value div {
  color: var(--tertiaryBackgroundColor);
  font-size: 12px;
  font-weight: 400;
}

.addAutomations > svg {
  max-width: 24px;
  max-height: 24px;
  flex-shrink: 0;
}
.transfer__status {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
}
.transfer__statusTitle {
  font-size: 18px;
  text-align: center;
  margin-top: 24px;
  color: var(--primary-color);
  font-style: italic;
}
.transfer__statusTitle span {
  font-weight: 700;
  font-style: normal;
}
.transfer__statusTitle-pending {
  text-align: center;
  margin-top: 24px;
  color: var(--primary-color);
  font-weight: 700;
}
.transfer__statusTitle-paused {
  text-align: center;
  font-weight: 700;
  color: var(--error);
}

.addAutomations {
  width: 52px;
  height: 52px;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 83%;
  right: 20px;
  border-radius: 48px;
  background: var(--primary-color);
  cursor: pointer;
  display: none;
}

.automations__addButtonHolder {
  /* margin: 20px 0; */
}
.automations__empty {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.display__btn {
  display: none;
}

/* MEDIA */
@media screen and (max-width: 1200px) {
  .automations__container {
    padding: 0 60px 40px;
  }
}

@media screen and (max-width: 1024px) {
  .bannerModalDashboard {
    /* max-height: 500px; */
  }
  .automations__container {
    align-items: center;
    /* max-width: 644px; */
    gap: 40px;
    padding-bottom: 60px;
    margin-top: 60px;
  }

  .automation__fixedValue {
    margin-top: 20px;
  }

  .automations__checkingCards {
    max-width: 100%;
  }
  .automations__savingsCards {
    max-width: 100%;
  }

  .automations__cardsHolder {
    flex-wrap: wrap;
  }

  .automations__header {
    gap: 20px;
  }

  .automation__balance-value {
    font-size: 24px;
  }

  .addAutomation__holder {
    padding: 20px;
  }

  .automations__header__top {
    font-size: 24px;
  }

  .automations__cardsContainer {
    padding: 32px 20px;
  }

  .automations__cardsDetails {
    margin-bottom: 20px;
  }

  .automationsBlock {
    gap: 24px;
  }

  .automations__holder__chart {
    padding: 16px;
  }

  .cardsBlock {
    gap: 32px;
  }

  .automations__card {
    padding: 24px;
    max-width: 100%;
  }

  .automations__card-info-value {
    font-size: 20px;
  }

  .automations__cardsDivider-value > div > span {
    color: var(--mainFontColor);
    font-weight: 700;
  }

  .automations__cardsDivider {
    flex-direction: row-reverse;
  }

  .automations__cardsDivider-arrowsIcon {
    transform: rotate(90deg);
  }

  .transfer__statusTitle {
    font-size: 14px;
  }

  /* .viewMoreBlock {
    margin-bottom: 60px;
  }
   */
}
@media screen and (max-width: 743px) {
  .hidden__btn {
    display: none;
  }
  .automation__fixedValue {
    margin-top: 16px;
  }
  .display__btn {
    display: block;
  }
  .display__btn .btn {
    width: 100%;
  }
}

@media screen and (max-width: 743px) {
  .automationsBlock__header {
    .btn {
      padding: 0px 20px !important;
      width: 100%;
    }
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }
}

@media screen and (max-width: 743px) {
  .automations__container {
    gap: 40px;
    margin-top: 40px;
    padding: 0px 20px 40px 20px;
  }
  .automations__empty-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .automations__header {
    gap: 24px;
  }

  .automations__header__top {
    font-size: 20px;
  }

  .cardsBlock {
    gap: 10px;
  }

  .automations__card {
    padding: 16px;
    gap: 8px;
  }

  .automations__cardsDetails {
    margin-bottom: 16px;
  }

  .automations__cardsContainer {
    padding: 16px;
  }

  .automations__card-title {
    font-size: 14px;
  }

  .automations__card-info-value {
    font-size: 16px;
  }

  /* .automationsBlock__header>button {
    display: none !important;
  } */

  .addAutomations {
    display: flex;
  }
}
