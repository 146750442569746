input[name="settingsNewEmail"]:-webkit-autofill,
input[name="settingsNewPassword"]:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}
.headerMenu__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.backButton__holder {
  min-height: 25px;
}

.settings__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
  gap: 40px;
  padding: 40px 20px 80px;
  background: var(--secondary-black-color);
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
}

.profile__title {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--grey40);
  text-align: left;
  padding-bottom: 8px;
}

.profile > div {
  width: 100%;
}
.profile__blockInfo-delete {
  cursor: pointer;
}

.profile__blockInfo,
.profile__blockInfo-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border: 1px solid var(--grey20);
  border-radius: 16px;
  padding: 30px 40px;
  .customInput__holder {
    max-width: none;
    .customInput:read-only,
    .customInput:read-only + label {
      color: #707070;
    }
  }
  .customInput__holder.ssn svg {
    color: #707070 !important;
  }
}

@media screen and (max-width: 1024px) {
  .profile__blockInfo,
  .profile__blockInfo-delete {
    padding: 24px 40px;
  }
}

@media screen and (max-width: 743px) {
  .profile__blockInfo,
  .profile__blockInfo-delete {
    padding: 16px 20px;
  }
}

.address .customInput.hasPlaceholder ~ .customInput__label {
  font-size: 8px;
  transform: translateY(-10px);
}

.address .customInput.hasPlaceholder {
  padding: 16px 16px 8px 16px;
}

.address .customInput.hasPlaceholder::placeholder {
  color: #707070;
}

.email-error-msg {
  padding: 4px 0 16px 0;
  font-size: 18px;
  color: var(--error);
}

.profile__blockInfo .address,
.profile__blockInfo .password__conditions-container {
  width: 100%;
}

.account__infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
  border-bottom: 1px solid var(--grey20);
}

.account__infoRow:first-child {
  padding-top: 0px;
}

.account__infoRow:last-child {
  border: none;
  padding-bottom: 0px;
}

.deleteBlock {
  padding: 0px;
}

.account__infoContainer {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 125px;
}

.account__blockSelectPeriod {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account__blockSelectPeriod > a {
  margin-left: 16px;
}

.account__infoRow__Data {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  width: auto;
  text-align: left;
  color: var(--grey40);
}

.profile__input {
  display: block;
  width: auto;
  background-color: var(--secondary-black-color);
  border-radius: 8px;
  font-weight: 700;
  color: var(--grey40);
}

.edit_password {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.block_accounts,
.block__notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .profile__blockInfo,
  .profile__blockInfo-delete {
    display: flex;
    /* padding: 40px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .profile__title {
    text-align: left;
  }

  .account__infoRow__Data {
    color: var(--grey30);
    font-size: 14px;
    font-weight: 400;
  }
}

/* -- reconnect */
.account__info__data {
  font-size: 18px;
}
.account__info__data > span {
  font-weight: 700;
}

.disconnected {
  color: var(--error);
}
.disconnected__icon {
  margin-left: 10px;
}
.reconnect_btn {
  background-color: var(--error);
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
}

.accounts__infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #707070;
}

.accounts__infoContainer:last-child {
  border: none;
  padding: 0;
}

.transactions__block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.settings-infoIcon {
  position: relative;
  padding: 0px 5px;
  cursor: pointer;
}

.settings-tooltiptext {
  visibility: hidden;
  width: 200px;
  height: auto;
  text-align: justify;
  position: absolute;
  z-index: 1;
  padding: 8px;
  top: 100%;
  right: 0;
  margin: 5px -83px 0px 0px;
  color: var(--grey30);
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 550;
  line-height: 16px;
  border-radius: 4px;
  background: var(--primary-color);
}

.settings-tooltiptext span {
  font-weight: 700;
}

.settings-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--primary-color) transparent;
}

.settings-infoIcon:hover .settings-tooltiptext {
  visibility: visible;
}

.react-toggle-track {
  background-color: var(--grey20, #707070) !important;
}

.react-toggle--checked {
  .react-toggle-track {
    background-color: var(--primary-color) !important;
  }
}

.react-toggle-thumb {
  border: none !important;
}

.account__cardName {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: var(--grey30);
  width: 200px;
}

.account__transactions {
  font-weight: 400;
  font-size: 16px;
  width: 100px;
}

.deleteAccount {
  max-width: 266px;
  flex-shrink: 0;
  color: var(--error);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.select-menu {
  padding: 10px;
  position: absolute;
  right: -21px;
  border-radius: 8px;
  width: 139px;
  height: auto;
  border: 1px solid var(--grey20);
  background: var(--grey10);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  z-index: 99;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.select-menu span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey30);
  cursor: pointer;
  line-height: 24px;
}

.eye_icon_settings {
  width: 22px;
  height: 22px;
  cursor: pointer;
  font-size: 22px;
}

.eye_icon_settings svg {
  color: var(--primary-color);
}

.page__editable_title {
  font-size: 24px;
}

.settings__title {
  font-size: 32px;
  font-weight: 700;
}

.reauth-dialog .reauth-dialog-paper {
  background-color: #000;
  padding: 40px;
  border: 1px solid #707070;
  border-radius: 16px;
  margin: 0 10px;
  max-width: 650px;
  min-height: 420px;
  width: 100%;
  background-image: none;
  box-shadow: none;
}

/* MEDIA */
@media screen and (max-width: 1200px) {
  .settings__container {
    padding: 40px 60px 80px;
  }
}

@media screen and (max-width: 1024px) {
  .settings__container {
    padding-top: 32px;
    gap: 60px;
  }
  .profile {
    gap: 60px;
  }
  .account__cardName,
  .account__infoRow__Data {
    font-size: 16px;
  }

  .profile__title {
    font-size: 20px;
    padding-bottom: 4px;
  }

  .account__infoContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .account__info__data {
    font-size: 16px;
  }
  .page__editable_title {
    font-size: 24px;
  }
  .settings__title {
    font-size: 28px;
  }
  .email-error-msg {
    font-size: 16px;
  }
  .block_accounts,
  .block__notification {
    gap: 20px;
  }
}

@media screen and (max-width: 743px) {
  .profile__title {
    padding-bottom: 0px;
  }
  .settings__container {
    padding: 24px 20px 40px;
    gap: 40px;
  }
  .profile {
    gap: 40px;
  }
  .account__info__data {
    font-size: 14px;
  }

  .account__cardName,
  .account__transactions,
  .account__infoRow__Data {
    font-size: 14px;
  }

  .page__editable_title {
    font-size: 24px;
  }
  .settings__title {
    font-size: 24px;
  }
  .reauth-dialog .reauth-dialog-paper {
    padding: 24px;
  }
  .email-error-msg {
    font-size: 14px;
  }
  .block_accounts,
  .block__notification {
    gap: 16px;
  }
}

.profile__edit_save {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}

.profile__title__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}

.reauth-dialog .MuiBackdrop-root {
  background-color: rgba(112, 112, 112, 0.5);
}

.reauth-dialog .MuiDialogContent-root {
  padding: 0;
}
.reauth-dialog .closeBtn {
  text-align: right;
  cursor: pointer;
}

.reauth-dialog .auth-actions {
  justify-content: center;
  padding: 24px 0 0 0;
}

.reauth-dialog .auth-title {
  padding-top: 24px;
  color: #eee;
  text-align: center;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}

.reauth-dialog .auth-text {
  padding: 24px 0 24px 0;
  color: #eee;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.reauth-dialog .auth-error {
  padding-top: 24px;
  color: #f66;
  text-align: center;
  /* Body/Body 18 */
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.reauth-dialog .customInput__holder {
  margin-bottom: 0;
}

.auth-password .MuiInputBase-root {
  border: 1px solid #39848a;
  height: 52px;
  background-color: #303030;
  border-radius: 8px;
}

.auth-password .MuiFormLabel-root,
.auth-password .MuiFormLabel-root.Mui-error,
.auth-password .MuiFormLabel-root.Mui-focused {
  color: #fafafa;
}

.auth-password .MuiInputBase-root.Mui-error {
  border: 1px solid #f66;
}

.auth-password .MuiInputBase-root::before,
.auth-password .MuiInputBase-root:hover::before,
.auth-password .MuiInputBase-root.Mui-focused::after {
  border-bottom: 0px !important;
}

.auth-password .MuiInputBase-root,
.auth-password .MuiInputBase-root.Mui-focused,
.auth-password .MuiInputBase-root:hover {
  background-color: #303030;
}

.auth-password .MuiButtonBase-root {
  margin-right: 0px;
}

@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .reauth-dialog .auth-text {
    padding: 20px 0 20px 0;
    font-size: 16px;
    line-height: 28px;
  }
  .reauth-dialog .auth-error {
    padding-top: 20px;
    font-size: 16px;
  }
  .reauth-dialog .auth-title {
    font-size: 28px;
    padding-top: 20px;
    line-height: 36px;
  }
  .reauth-dialog .auth-actions {
    padding: 20px 0 0 0;
  }
  .profile__title__container {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .reauth-dialog .auth-text {
    padding: 16px 0 16px 0;
    font-size: 14px;
    line-height: 20px;
  }
  .reauth-dialog .auth-error {
    padding-top: 16px;
    font-size: 14px;
  }
  .reauth-dialog .auth-title {
    font-size: 24px;
    padding-top: 16px;
    line-height: 32px;
  }
  .reauth-dialog .auth-actions {
    padding: 16px 0 0 0;
  }
  .profile__title__container {
    padding-bottom: 16px;
  }
}
