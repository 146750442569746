.recovery__infoContainer{
   display: flex;
   flex: 1;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.recovery__errorMassage {
    display: block;
    width: auto;
    text-align: center;
    font-size: 18px;
    color: var(--error);
    font-weight: 400;
    margin: 14px 0;
}

.resend{
    color: var(--primary-color);
    font-weight: 400;
}

.recovery-password-back-btn {
    padding-top: 40px;
}

@media screen and (max-width: 1024px) {
    .recovery-password-back-btn {
        padding-top: 32px;
    }
    .recovery__errorMassage {
        font-size: 16px;
    }
}

@media screen and (max-width: 743px) {
    .recovery-password-back-btn {
        padding-top: 20px;
    }
    .recovery__errorMassage {
        font-size: 14px;
    }
}
