.btn {
  padding: 16px 32px;
  border-radius: 8px;
  font-weight: 700;
  margin: 0 auto;
}

.btn:disabled{
  color: var(--grey30);
  background-color: var(--primary-color);
  opacity: 0.5;
  cursor: default;
}

.btn-flex {
  /* added if button with icon  */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--primary-color);
  color: var(--grey30);
}

/* Add automation button */
.add_automation{
  padding: 0px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--primary-color);
}
.add_automation p{
  font-size: 35px;
  font-weight: 400; 
}

/* for button with icon in Sign Up flow 'Link account' */
.btn img {
  /* width: 40px;
  height: 40px; */
  margin-right: 8px;
}

.black-btn {
  background-color: var(--black-color);
  color: #fafafa;
}

.white-btn {
  background-color: #fafafa;
  color: var(--black-color);
  border: 2px solid #303030;
  padding: 15.2px 32px;
}

.primary-btn{
  background-color: var(--primary-color);
  color: var(--grey30);
}
.secondary-btn{
  background-color: var(--secondary-black-color);
  color: var(--primary-color);
 
}
.warning-btn{
  color: var(--error);
  border: 1px solid var(--error);
  font-size: 16px;
  padding: 16px 24px;
}

.plaid-btn{
  color: var(--primary-color);
  display: flex;
  border: 1px solid var(--grey20);
  border-radius: 16px;
  padding: 40px 24px;
  width: 100%;
}
.modalPlaid-btn{
  width: 310px;
  font-size: 18px;
  padding: 16px;
  margin-top: 40px;
  background-color: var(--primary-color);
}

.disabled{
  background-color:  var( --gray-dark-color);
}

@media screen and (max-width: 1024px) {
  .warning-btn{
    padding: 16px 16px;
  }
  .modalPlaid-btn{
    width: 284px;
    font-size: 16px;
    margin-top: 36px;
  }
}
/* mobile */

@media screen and (max-width: 743px) {
  .warning-btn{
    padding: 16px 8px;
  }
  .btn {
    width: 100%;
    /* font-size: 12px ; */
  }
  .modalPlaid-btn{
    width: 100%;
    font-size: 14px;
    margin-top: 32px;
  }
}