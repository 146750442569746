.header__holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.edit__titleHolder {
  margin-top: 70px;
}
.deleteAutomation__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--error);
  margin: 40px auto;
  width: fit-content;
}
.deleteAutomation__btn-icon {
  display: block;
  display: flex;
  align-items: center;
}
.confirmButton__holder {
  margin-top: 70px;
  text-align: center;
}
.balanceInput__holder {
  max-width: 138px;
  margin: 0 auto;
  margin-top: 24px;
}
.deletebtn_title {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.edit-automation__container {
  margin: 0 0 0;
}

.edit-automation-container-wrapper .confirmButton__holder {
  margin-top: 0;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .balanceInput__holder {
    margin-top: 20px;
  }
  .edit__titleHolder {
    margin-top: 50px;
  }
  .confirmButton__holder {
    margin-top: 60px;
  }
  .deletebtn_title {
    font-size: 16px;
  }
  .edit-automation__container {
    margin: 0 0 0;
  }
}

@media screen and (max-width: 743px) {
  .balanceInput__holder {
    margin-top: 16px;
  }
  .edit__titleHolder {
    margin-top: 30px;
  }
  .confirmButton__holder {
    margin-top: 40px;
  }
  .edit-automation__container {
    margin: 0 0 0;
  }
}
/* END */
