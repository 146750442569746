.verifyEmail__infoContainer {
  margin: 80px 0px;
  flex: 1;
}

.verifyEmail__subtitle{
  font-size: 18px;
  margin-bottom: 36px;
  text-align: center;
}

.verifyEmail__resendEmail{
  text-align: center;
  font-size: 18px;
}

.verifyEmail__resendEmail a{
 color: var(--primary-color);
}

@media screen and (max-width: 1024px) {
  .verifyEmail__infoContainer {
    margin: 60px 0px;
  }
  .verifyEmail__resendEmail{
    font-size: 16px;
  }
  .verifyEmail__subtitle{
    font-size: 16px;
    margin-bottom: 32px;
  }
  
}
@media screen and (max-width: 743px) {
  .verifyEmail__infoContainer .otp-input-container {
    gap: 8px;
  }

  .verifyEmail__infoContainer {
    margin: 40px 0px;

  }
  .verifyEmail__subtitle{
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  .verifyEmail__resendEmail{
    font-size: 14px;
  }
}
