.input__holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userName__errorMassage {
  text-align: center;
  color: var(--error);
  font-size: 18px;
  font-weight: 400;
}
.haveAcc {
  margin-bottom: 80px;
  font-size: 18px;
  text-align: center;
}
.conditions {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 80px;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .haveAcc {
    font-size: 16px;
    margin-bottom: 60px;
  }

  .conditions {
    font-size: 16px;
    margin-bottom: 60px;
  }
  .userName__errorMassage {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .haveAcc {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .conditions {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .userName__errorMassage {
    font-size: 14px;
  }
}
