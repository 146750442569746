.checkingAcc__holder {
  margin: 0 auto;
  max-width: 624px;
  padding: 16px;
  border: 1px solid var(--grey20);
  border-radius: 16px;
}
.savingsAcc__holder {
  margin: 0 auto;
  max-width: 624px;
}
.arrows {
  text-align: center;
  padding: 16px 0;
}
.account__btn {
  width: 100%;
  border: 1px solid var(--grey20);
  border-radius: 16px;
  padding: 24px;
}
.accountButton__infoContainer {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  gap: 4px;
}
.accountButton__title {
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--primary-color);
}
.disable {
  color: var(--grey20);
}

/* MODAL*/
.hideModal {
  display: none;
}
.modalAccountsList {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(112, 112, 112, 0.5);
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.modalAccountsList__container {
  max-width: 624px;
  min-height: 490px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--secondary-black-color);
  border: 1px solid rgba(112, 112, 112, 1);
  padding: 20px;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.closeModalBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.modalAccountsList__title {
  font-size: 18px;
  margin: 24px 0;
  color: var(--grey40);
  font-weight: 700;
  padding-top: 24px;
  text-align: center;
  border-top: 1px solid rgba(112, 112, 112, 1);
}
.modalAccountsList__cardsHolder {
  max-height: 490px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
/* BALANCE */
.balanceInput__holder {
  display: flex;
  justify-content: center;
}
.balanceInput {
  padding: 14px 0px;
  background-color: var(--grey10);
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 8px;
}
.balanceInput:disabled::placeholder {
  color: var(--grey20);
}
.balanceInput__error {
  border: 1px solid var(--error);
}
.addAutomation__nextButton {
  text-align: center;
  margin-top: 80px;
}
.incorrect_choice {
  max-width: 625px;
  text-align: center;
  color: var(--error);
  text-align: center;
  margin: 10px auto;
}

.error__message.account-exist {
  color: var(--error);
  margin-top: 24px;
}

.error__message.account-exist.desktop-error {
  display: block;
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .modalAccountsList__title {
    font-size: 16px;
    margin: 20px 0;
    padding-top: 20px;
  }
  .addAutomation__nextButton {
    margin-top: 60px;
  }
  .error__message.account-exist {
    margin-top: 20px;
  }
}

@media screen and (max-width: 743px) {
  .modalAccountsList__title {
    font-size: 14px;
    margin: 16px 0;
    padding-top: 16px;
  }
  .error__message.account-exist {
    margin-top: 16px;
  }
  .addAutomation__nextButton {
    margin-top: 5px;
  }
  .modalAccountsList__container {
    max-width: 624px;
    min-height: 60%;
    width: 100%;
    margin: 0 auto;
    background-color: var(--secondary-black-color);
    border-top: 1px solid rgba(112, 112, 112, 1);
    padding: 10px;
    border-radius: 16px 16px 0 0;
    position: absolute;
    transform: translateY(-17%);
    bottom: 0;
  }
}

.add-automation-dialog .add-automation-dialog-paper {
  background-color: #000;
  padding: 20px;
  border: 1px solid #707070;
  border-radius: 16px;
  margin: 0 10px;
  max-width: 624px;
  min-height: 490px;
  width: 100%;
  background-image: none;
  box-shadow: none;
  overflow: hidden;
}

.add-automation-dialog .MuiBackdrop-root {
  background-color: rgba(112, 112, 112, 0.5);
}
