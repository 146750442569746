.address__infoContainer {
    margin: 0px auto;
    margin-bottom: 24px;
    max-width: 570px;
  }
  
  @media screen and (max-width: 1024px) {
    .address__infoContainer {
      max-width: 624px;
      margin-bottom: 20px;
    }
  }
  
  @media screen and (max-width: 743px) {
    .address__infoContainer {
      margin-bottom: 16px;
    }
    .dateBirth__input__holder {
      margin-bottom: 24px;
    }
  }