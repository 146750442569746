.credit_data {
  display: flex;
  gap: 32px;
}
.creditCard_name {
  margin-bottom: 0;
  text-align: start;
  font-size: 18px;
}
.bankCard__value {
  color: var(--Greyscale-Greyscale-40, #fafafa);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.colBlock {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent_block {
  display: flex;
  flex-direction: row;
  gap: 60px;
}
.info_cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.demo_creditCardDashboard {
  padding-inline: 16px;
  padding-block: 10px;
  border-radius: 8px;
  display: flex;
  gap: 45px;
  border: 1px solid var(--Primary, #39848a);
  background: var(--Greyscale-Greyscale-10, #303030);
  margin-block: 24px 12px;
}
.footer_creditCard_text {
  text-align: center;
  color: var(--Primary, #39848a);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  width: 95%;
}

.credit_dataDemo_dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.status {
  font-size: 18px;
}
.demo_bankCard__number {
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  .demo_creditCardDashboard {
    gap: 110px;
  }
  .creditCard_name {
    text-align: start;
    font-size: 16px;
  }
  .max_and_optimiz_block_tablet {
    width: 100%;
  }
  .optimiz_method_mobile {
    width: 100%;
  }
  .titles_of_max_and_opt_tablet {
    font-size: 16px;
  }
  .max_opt_title_mobile {
    margin-block: 25px 15px;
  }
  .info_cont {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .status {
    font-size: 16px;
  }
  .bankCard__value {
    font-size: 16px;
  }
  .parent_block {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .colBlock {
    width: 100%;
    gap: 10px;
  }
  .credit_dataDemo_dashboard {
    flex-direction: column;
    align-items: start;
  }
  .demo_dashboard_page_info_container {
    padding: 60px;
  }
  .max_opt_title {
    font-size: 16px;
  }
  .footer_creditCard_text {
    font-size: 16px;
  }
  .demo_info {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 743px) {
  .demo_bankCard__number {
    font-weight: 400;
  }
  .creditCard_name {
    text-wrap: nowrap;
    font-size: 14px;
  }
  .demo_creditCardDashboard {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
  }
  .demo_dashboard_page_info_container {
    padding: 40px;
  }
  .status {
    font-size: 14px;
    text-wrap: nowrap;
  }
  .bankCard__value {
    text-align: end;
    font-size: 14px;
  }
  .credit_automation_block {
    padding: 16px;
  }
  .credit_dataDemo_dashboard {
    flex-direction: column;
  }
  .footer_creditCard_text {
    width: 95%;
    font-size: 14px;
  }

  .demo_info {
    display: flex;
    gap: 5px;
  }

  .credit_dataDemo_dashboard {
    width: 100%;
    flex-direction: row;
    margin-block-end: 16px;
    align-items: center;
    justify-content: start;
  }
  .parent_block {
    margin-inline-end: 0px !important;
    width: 100%;
  }

  .info_cont {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
  }

  .info_cont > * {
    flex: 1;
    margin: 0;
  }
  .optimiz_method_mobile {
    width: 100%;
  }

  .max_and_optimiz_block_mobile {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  .remainingStatementBalanceText {
    white-space: wrap;
  }
}
