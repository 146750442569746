/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scrollbar-gutter: stable;
}

body {
  padding: 0!important;
}

.article p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.article {
  font-size: 16px;
  line-height: 24px;
  padding: 0 20px;
}

.article ol,
.article ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
  cursor: default;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none;
}
input::placeholder {
  color: var(--grey30);
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  /* --white-color: #ffffff;
  --gray-light-color: #f9f9f9;
  --gray-medium-color: #eeeeee;
  --gray-dark-color: #d5d5d5;
  --black-color: #303030;
  --black-light-color: #707070; */

  /* dark theme  (main theme)*/
  /* Main Color */
  --primary-color: #39848a;
  --secondary-orange-color: #eb853f;
  --secondary-black-color: #000000;
  /* Greyscale */
  --grey10: #303030;
  --grey20: #707070;
  --grey30: #eeeeee;
  --grey40: #fafafa;
  /* Notification Colors */
  --success: #75f08a;
  --warning: #ffdd33;
  --error: #ff6666;
  /* buttons & link */
  --button-disabled: #d7e6e8;
  --button-focused: #48a6ad;
  --button-pressed: #67bac0;
  --link-hover-background: #d4ebed;
}

body {
  background: var(--secondary-black-color);
  /* font-family: 'Inter', sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: var(--grey30);
  cursor: default;
  }

body.reset-mui-overflow {
  overflow: initial !important;
}

.mainContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.container {
  padding: 0px 20px 20px 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* CLASS FOR ALL TITLE ON SIGNUP PAGES */
.page__title {
  color: var(--grey40);
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 36px;
  cursor: default;
}

.page__title.smaller-size {
  margin-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .container, .article {
    padding: 0 60px 20px;
  }

  .app-header .header-container {
    padding-right: 60px!important;
    padding-left: 60px!important;
  }
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .page__title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
  }

  .page__title.smaller-size {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .container, .article {
    padding: 0 20px 20px;
  }

  .app-header .header-container {
    padding-right: 20px!important;
    padding-left: 20px!important;
  }

  .page__title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .page__title.custom_mobile {
    margin-bottom: 16px;
  }

  .page__title.smaller-size {
    margin-bottom: 16px;
  }
}
/* END */

/* CLASS FOR ALL SUBTITLE ON SIGNUP PAGES */
.page__subtitle {
  color: var(--grey30);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 40px;
  cursor: default;
}

.page__subtitle.smaller-size {
  margin-bottom: 24px;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .page__subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .page__subtitle.smaller-size {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 743px) {
  .page__subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .page__subtitle.smaller-size {
    margin-bottom: 16px;
  }
}
/* END */

/* CLASS FOR INFO SUBTITLE ON SIGNUP PAGES */
.info__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: default;
}
@media screen and (max-width: 1024px) {
  .info__subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 743px) {
  .info__subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}

/* END*/

/* CLASS FOR INFOCONTAINER ON SIGNUP PAGES */
.page__infoContainer {
  margin: 80px 0px 80px 0px;
  flex: 1;
}

@media screen and (min-width: 1025px) {
  .page__infoContainer.sign-up-name-container,
  .page__infoContainer.sign-up-email-container,
  .page__infoContainer.sign-up-password-container {
    margin: 80px 0px 56px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .page__infoContainer {
    margin: 60px 0px 60px 0px;
  }
}

@media screen and (max-width: 743px) {
  .page__infoContainer {
    margin: 40px 0px 40px 0px;
  }
}
/* END */

/* CLASS FOR DASHBOARD TITLE */
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 16px;
  }
}
/* END */

/* CLASS FOR BUTTON HOLDER SIGNUP PAGES */
.button__holder {
  /* margin-top: 20px; */
  /* margin-bottom: 60px; */
  text-align: center;
}
/* @media screen and (max-width: 1024px) {
  .button__holder {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 743px) {
  .button__holder {
    margin-bottom: 0px;
  }
} */
/* END */

/* CLASS FOR TEXT FONTSIZE */
.text__content {
  font-size: 18px;
}
@media screen and (max-width: 1024px) {
  .text__content {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .text__content {
    font-size: 14px;
  }
}
/* END */

.text-center {
  text-align: center;
}

.error__msg{
  color: var(--error);
}
