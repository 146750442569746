.liability_form__infoContainer {
  height: 100%;
  margin: 80px 140px;
  flex: 1;
  max-height: 100vh;
}
.liability_form_input_title {
  margin-block-end: 36px;
}
.liability_form_input_subTitle {
  margin-block-end: 24px;
}
.backButton {
  margin-top: 40px;
}
.liability_FormNextButton_holder {
  position: fixed;
  bottom: 80px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.backButton_liability_form {
  margin-block-end: 80px;
}
.disabledCreditCard {
  pointer-events: none;
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  /* html, body {
    overflow: auto;
  } */
  .backButton_liability_form {
    margin-block-end: 60px;
  }
  .liability_form__infoContainer {
    margin: 60px 60px;
  }
  .liability_form_input_subTitle {
    margin-block-end: 60px;
  }
  .liability_FormNextButton_holder {
    bottom: 60px;
  }
}
@media screen and (max-width: 743px) {
  .backButton_liability_form {
    margin-block-end: 40px;
  }
  .liability_FormNextButton_holder {
    bottom: 40px;
    width: calc(100% - 40px);
    left: 20px;
    right: 20px;
  }
  .liability_form_input_subTitle {
    margin-block-end: 40px;
  }
  .liability_form_input_title {
    margin-block-end: 16px;
  }
  .liability_form__infoContainer .otp-input-container {
    gap: 8px;
  }

  .liability_form__infoContainer {
    margin: 40px 20px;
  }
}
