.verifyAccount__infocontainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.verifyAcc__icon {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifyAcc__subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  max-width: 766px;
  margin: 0 auto;
}
/* loader */
.verify__loader {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 8px solid var(--primary-color);
  border-top: 8px solid black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .verifyAcc__subtitle {
    font-size: 16px;
  }
}
@media screen and (max-width: 743px) {
  .verifyAcc__subtitle {
    font-size: 14px;
  }
}
