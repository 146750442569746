/* Desktop: */

.blog_subtitle {
  margin: 24px 0 0 0;
  color: var(--Greyscale-Greyscale-40, #fafafa);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  max-width: 620px;
}

.main_title_post {
  font-family: Open Sans;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  text-align: left;
}

.layout_posts {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.pagination-blog{
  display: flex;
  justify-content: center;
  color: var(--Primary, #39848a) !important;
}

.pagination-blog .css-1ww1bmm-MuiButtonBase-root-MuiPaginationItem-root {
  color: var(--Primary, #39848a) !important;
}

.pagination-blog .css-1ww1bmm-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  color:#fafafa !important;
}

.pagination-blog .MuiPagination-ul .css-xg6lcp-MuiPaginationItem-root {
  color: var(--Primary, #39848a) !important;
}

.pagination-blog .css-1dqnzm8 {
  color: var(--Primary, #39848a) !important;
}

.css-1dqnzm8.Mui-selected {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(57, 132, 138) !important;
}

.css-1dqnzm8 {
  color: var(--Primary, #39848a) !important;
}


/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .layout_posts {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .main_title_post {
    font-family: Open Sans;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 37px !important;
    text-align: left;
  }
  .layout_content_post_card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: left !important;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .layout_posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .main_title_post {
    font-family: Open Sans;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left;
  }
}

/* Mobile: */
@media screen and (max-width: 385px) {
  .layout_posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .main_title_post {
    font-family: Open Sans;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left;
  }
}
