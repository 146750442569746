.reconnect-account-dialog {
  background-color: hsla(0,0%,44%,.6);
}
.reconnect-account-dialog-paper.MuiDialog-paper {
  background-color: #000;
  background-image: none;
  width: 100%;
  margin: 0 10px;
  max-width: 1240px;
  box-shadow: none;
  border: 1px solid #707070;
  border-radius: 12px;
  padding: 40px 20px 40px 40px;
}

.reconnect-account-dialog-paper .MuiDialogContent-root {
  padding: 0 20px 0 0;
}

.reconnectCard__holder {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 32px;
  border: 1px solid #707070;
  border-radius: 16px;
  margin: 16px 0 40px 0;
}
.reconnectModal__content-title{
  text-align: center;
  margin-top: 40px;
}
.reconnectButton_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.reconnectModal__content-subtitle {
  margin-bottom: 24px;
}
.closeButton {
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .reconnectCard__holder {
    flex-direction: column;
    align-items: center;
  }
  .reconnectButton_holder {
    flex-direction: column;
  }
}


@media screen and (max-width: 743px) {
  .reconnectButton_holder div {
    width: 100%;
  }
  .reconnectButton_holder div button {
    width: 100%;
  }
  .reconnect-account-dialog-paper.MuiDialog-paper {
    padding: 24px 12px 24px 24px;
  }
  .reconnect-account-dialog-paper .MuiDialogContent-root {
    padding: 0 12px 0 0;
  }
  .reconnectCard__holder {
    gap: 16px;
  }
}
