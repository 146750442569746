.credit_automation_block {
  border: 0.1px solid #fafafa;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_automation_mobile {
  display: none;
}
.active_automations_row {
  display: flex;
  justify-content: space-between;
  margin-block-end: 24px;
}
.demo_account_card {
  margin-block-start: 24px;
}

.arrows_icon_container_demodashboard {
  margin-block-start: 24px;
}
.demo_creditCardDashboard {
  width: 100%;
}
.demo_dashboard_page_info_container {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  width: 100%;
  padding-block: 80px;
  padding-inline: 80px;

  margin: 0 auto;
}
.max_and_optimiz_block_tablet {
  display: none;
}

.parent_container {
  display: flex;
  justify-content: center;
}

.demo_account_card {
  max-width: 1030px;
  min-height: 110px !important;
  border: 1px solid #39848a;
}

.max_amount {
  display: flex;
  height: 52px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.5px solid #48a6ad;
  background: rgba(57, 132, 138, 0.3);
  width: 100%;
}

.optimiz_method {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #39848a;
  width: 100%;
}
.parent_max_and_opt_block {
  display: flex;
  width: 100%;
  justify-content: center;
}
.max_and_optimiz_block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-block-start: 24px;
  justify-content: center;
}

.titles_of_max_and_opt {
  display: flex;
  justify-content: space-around;
}

.val_of_max_and_opt {
  display: flex;
  gap: 16px;
}

.max_opt_title {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.opt_block {
  display: flex;
  width: 50%;
}

.not_chosen_opt {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: var(--Primary, #39848a);
  border-radius: 8px;
  border: 2px solid var(--Primary, #39848a);
}

.arrows_icon_container_demodashboard {
  margin-inline-end: 15px;
}

/* Mobile  */
.max_and_optimiz_block_mobile {
  display: none;
}

.max_opt_title_mobile {
  display: flex;
  justify-content: center;
  margin-block: 24px 12px;
  font-size: 14px;
  font-weight: 700;
}

.opt_block_mobile {
  display: flex;
  justify-content: center;
  width: 100%;
}

.optimiz_method_mobile {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #39848a;
  text-wrap: nowrap;
  width: 50%;
}

.not_chosen_opt_mobile {
  text-wrap: nowrap;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  color: var(--Primary, #39848a);
  border-radius: 8px;
  border: 2px solid var(--Primary, #39848a);
  width: 50%;
}
.edit_demo_bttn {
  width: 100%;
  text-align: end;
  font-size: 16px;
  color: var(--Primary, #39848a);
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  .credit_automation_block {
    padding: 16px;
  }
  .demo_creditCardDashboard {
    flex-direction: row;
  }
  .max_and_optimiz_block_tablet {
    display: block;
  }
  .opt_block {
    height: 52px;
  }
  .demo_dashboard_page_info_container {
    padding-inline: 60px;
  }
  .add_automation_mobile {
    display: none;
  }
  .opt_block {
    width: fit-content;
  }
  .max_and_optimiz_block {
    display: flex;
    flex-direction: column;
  }
  .parent_max_and_opt_block {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .demo_creditCardDashboard {
    flex-direction: column;
  }
  .edit_demo_bttn {
    width: 100%;
    text-align: end;
    font-size: 16px;
    color: var(--Primary, #39848a);
  }
  .add_automation_mobile_button .demo_dashboard_page_info_container {
    padding-inline: 20px;
    padding-block: 40px;
  }
  .active_automations_row {
    margin-block-end: 0px;
    gap: 24px;
    align-items: flex-start;
  }
  .max_opt_title {
    font-size: 14px;
  }
  .val_of_max_and_opt {
    width: 100%;
  }
  .opt_block {
    width: fit-content;
  }
  .optimiz_method {
    text-wrap: nowrap;
  }
  .not_chosen_opt {
    text-wrap: nowrap;
  }
  .max_and_optimiz_block {
    width: 200px;
    display: none;
  }
  .max_and_optimiz_block_mobile {
    display: flex;
  }
  .add_automation_mobile_button {
    display: none;
  }
  .add_automation_mobile {
    display: block;
    margin-block-start: 16px;
  }
  .parent_max_and_opt_block {
    display: none;
  }
}
