.demo_connection__button {
  display: block;
  padding: 56px;
  /* max-width: 592px; */
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 16px;
  background-color: var(--grey10);
  margin: 0 auto;
}
.demo_connection__button.card-with-error {
  border: 1px solid var(--error);
}
.connection__button-disable {
  border: none;
  background-color: #303030;
  opacity: 0.5;
  cursor: default;
}

.connection__button-logo {
  text-align: center;
}
.demo_current__account {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
}

.current__account-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-color);
}
.disabledBtn {
  color: var(--grey40);
}

.current__account-discription {
  text-align: center;
}
.current__account-discription span {
  font-weight: 700;
}

/* MEDIA */
@media screen and (max-width: 1024px) {
  .demo_connection__button {
    min-height: 212px;
  }
  .current__account-title {
    font-size: 16px;
  }

  .connection__button {
    min-height: auto;
  }
}

@media screen and (max-width: 743px) {
  .demo_connection__button {
    min-height: 200px;
  }
  .current__account-title {
    font-size: 14px;
  }
  .connection__button {
    padding: 24px;
  }
}
/* END */
