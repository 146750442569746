.block {
  margin-inline: 30px;
}
.keyBank {
  height: 23px;
}
.keyLogo {
  text-align: center;
}
.rfm-marquee-container {
  align-items: center;
}
.greyBox {
  padding-block: 80px;
}
.block-title {
  display: "inline-block";
  text-align: center;
  padding-block-end: 48px;
  font-size: 28px;
  font-weight: 700;
  width: "fit-content";
}
@media screen and (max-width: 760px) {
  .greyBox {
    padding-block: 60px;
    white-space: nowrap;
  }
  .block-title {
    font-size: 18px;
    padding-block-end: 32px;
  }
  .rfm-marquee-container {
    align-items: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 760px) {
  .greyBox {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }
  .block-title {
    font-size: 24px;
    padding-block-end: 32px;
  }
  .rfm-marquee-container {
    align-items: center;
  }
}

@media screen and (max-width: 743px) {
  .greyBox {
    padding: 60px 24px;
  }
}
