.footer_text {
  font-size: 18px;
}
.selected_accounts_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px !important;
  margin-block-end: 32px;
}
.arrows_arrows_of_connected_accounts {
  width: 69px;
  height: 32px;
}
.optimization_goal_block {
  margin-block-end: 80px;
}
.connectAccounts_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #737373;
  border-radius: 16px;
  padding: 32px;
}
.setup_credit_cards {
  margin-bottom: 0px;
}
.error_connect_accounts_container {
  border: 1px solid #f66 !important;
}
.optimizationContainer {
  margin-block-end: 80px;
}
.maximal_disabled_balance_holder {
  font-size: 16px;
  margin-block-start: 0px;
}
.max_subtitle {
  margin-block-end: 0px;
}
.connectAccBttn {
  margin-block-end: 24px;
}
.credit_sub_title_maximal {
  padding-block-end: 40px;
}
.arrows_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 24px;
}
.disabled_creditCard {
  border-radius: 8px;
  border: 1px solid var(--Primary, #39848a);
  opacity: 0.5;
}
.credicard_automation_setUp_next_bttn {
  position: relative;
  margin-block: 80px;
}
.maximum_amount_input {
  display: flex;
  width: 194px;
  height: 52px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1.5px solid #48a6ad;
  background: rgba(57, 132, 138, 0.3);
}
.tip_text {
  margin-block-start: 24px;
}
.after_connected_acounts {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.accounts_connected {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.arrows_of_connected_accounts {
  transform: rotate(90deg);
}
.account_card_container {
  width: 100%;
}
.credit_cards_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.mobile_and_tablet_connect_accunts_container {
  align-items: center;
  border: 1px solid #737373;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.account_card_container {
  border-radius: 16px;
  border: 1px solid rgba(72, 166, 173, 0.4);
}

.arrows_arrows_of_connected_accounts {
  transform: rotate(-90deg);
}
.accountCardDisabled {
  cursor: not-allowed;
  pointer-events: none;
}

.maximum_amount_input {
  text-align: center;
}

.maximum_amount_input::placeholder {
  text-align: center;
}
.maximal_input_error {
  border: 1px solid #f66;
}
.maximal_amount_error_message {
  margin-block-start: 16px;
}
.demo_togglebutton {
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--Primary, #39848a);
  color: var(--Primary, #39848a);
  text-align: center;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.chosen_toggle_button {
  width: 100%;
  color: var(--Greyscale-Greyscale-40, #fafafa);
  text-align: center;
  border-radius: 8px;
  background: #39848a;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.toggle_buttons_container {
  display: flex;
  gap: 16px;
  width: 100%;
}
.optimizationPage_cotainer {
  display: flex;
  flex-direction: column;
}
.optimization_arrow {
  margin-block: 124px;
}
.connectAccounts_container_optimization_info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border: 1px solid #737373;
  border-radius: 16px;
  padding: 32px;
  gap: 16px;
}
.credit_car_and_maximalval {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.optimization_credit_cards {
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}
.optimization_block_arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  margin-block-end: 20px;
}
.connectAccounts_container_bank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #737373;
  border-radius: 16px;
  padding: 32px;
}
@media screen and (max-width: 1024px) {
  .footer_text {
    font-size: 16px;
  }
  .optimization_goal_block {
    margin-block-end: 60px;
  }
  .optimizationContainer {
    margin-block-end: 0px;
  }
  .connectAccounts_container_optimization_info {
    flex-direction: column;
  }

  .credicard_automation_setUp_next_bttn {
    position: relative;
    margin-block-start: 60px;
  }
  .arrows_icon_container {
    margin-block: 20px;
  }
  .after_connected_acounts {
    gap: 20px;
  }
  .connectAccounts_container {
    margin-block-end: 60px;
  }
  .optimization_arrow {
    display: flex;
    align-self: center;
  }
}
@media screen and (max-width: 743px) {
  .footer_text {
    font-size: 14px;
    text-align: center;
  }
  .toggle_buttons_container {
    flex-direction: column;
  }
  .optimization_goal_block {
    margin-block-end: 40px;
  }
  .connectAccounts_container {
    margin-block-end: 40px;
  }
  .connectAccounts_container_bank {
    margin-block-end: 0px;
  }
  .credicard_automation_setUp_next_bttn {
    position: relative;
    margin-block-start: 40px;
  }
  .arrows_icon_container {
    margin-block: 16px;
  }
  .footer_text {
    width: 80%;
  }
  .connect_accounts_title {
    margin-block-end: 24px;
  }
  .after_connected_acounts {
    gap: 16px;
  }
  .set_your_maximal_amount_title {
    width: 95%;
  }
  .tip_text {
    text-align: center;
  }
  .arrows_mobile_connected_accounts {
    width: 32px;
    height: 32px;
  }
}
