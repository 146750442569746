.backButton {
  margin-top: 40px;
}
.button_holder_display_none {
  display: none;
}
.savings_dashboard_disabled {
  cursor: default;
  opacity: 0.5 !important;
}
.demo_error_text {
  margin-block-end: 0px;
}
.error__message {
  margin-block-start: 24px;
}
.balance_info_container {
  display: flex;
  justify-content: center;
}
.balance-info {
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  width: 70%;
}
.optimal_balance_block {
  margin-block-start: 80px;
}
.balance-info .highlight {
  color: #39848a;
  font-weight: bold;
}

.automation__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--grey10);
  padding: 32px;
  border-radius: 16px;
  margin: 24px 0 24px 0;
  flex-grow: 1;
}
.page__subtitle.automation__description {
  margin-bottom: 24px;
}
.demo_connectionButton__holder {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.demo_arrows_Icon {
  /* margin-top: 115px; */
  min-width: 32px;
  transform: rotate(90deg);
  z-index: 0;
}
.btn-holder {
  padding: 40px;
  margin: 0 auto;
}
.wrong__choise {
  color: var(--error);
  max-width: 650px;
  text-align: center;
}
.wrong__choise-hide {
  display: none;
  color: var(--error);
  max-width: 650px;
  text-align: center;
  margin: 10px auto;
}

.error-text {
  text-align: center;
  margin-block-start: 24px;
}
.error_border {
  border: 1px solid var(--error);
}
.automation_subtitle {
  color: var(--grey30);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-block-end: 80px;
}
.cache_from_subtitle {
  font-size: 18px;
  font-weight: 700;
}
.checkingAcc__holder {
  margin: 0 auto;
  max-width: 624px;
  padding: 16px;
  border: 1px solid var(--grey20);
  border-radius: 16px;
}
.savingsAcc__holder {
  margin: 0 auto;
  max-width: 624px;
}
.arrows {
  text-align: center;
  padding: 16px 0;
}
.demo_arrows_Icon {
  margin-block-start: 75px !important;
}
.account__btn {
  width: 100%;
  border: 1px solid var(--grey20);
  border-radius: 16px;
  padding: 24px;
}
.accountButton__infoContainer {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  gap: 4px;
}
.accountButton__title {
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--primary-color);
}
.disable {
  color: var(--grey20);
}

/* MODAL*/
.hideModal {
  display: none;
}
.modalAccountsList {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(112, 112, 112, 0.5);
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalAccountsList__container {
  max-width: 624px;
  min-height: 490px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--secondary-black-color);
  border: 1px solid rgba(112, 112, 112, 1);
  padding: 20px;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.closeModalBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.modalAccountsList__title {
  font-size: 18px;
  margin: 24px 0;
  color: var(--grey40);
  font-weight: 700;
  padding-top: 24px;
  text-align: center;
  border-top: 1px solid rgba(112, 112, 112, 1);
}
.modalAccountsList__cardsHolder {
  max-height: 490px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
/* BALANCE */
.inputContainer {
  display: flex;
  align-items: center;
}
.balanceInput__holder {
  display: flex;
  justify-content: center;
}
.dollar-sign {
  position: absolute;
  margin-inline-start: 80px;
}
.balanceInput {
  padding: 14px 0px;
  background-color: var(--grey10);
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 8px;
}
.balanceInput:disabled::placeholder {
  color: var(--grey20);
}
.balanceInput__error {
  border: 1px solid var(--error);
}
.addAutomation__nextButton {
  margin-block: 8px;
  text-align: center;
}
.incorrect_choice {
  max-width: 625px;
  text-align: center;
  color: var(--error);
  text-align: center;
  margin: 10px auto;
}
.account-exist {
  text-align: center;
  width: 80%;
  margin-block-start: 24px;
}

.error__message.account-exist {
  color: var(--error);
  margin-top: 24px;
}
.error__message.account-exist.desktop-error {
  display: block;
}
.subtitle_block {
  margin-block-end: 80px;
}
.optimal_balance_title {
  font-size: 28px;
  margin-block-end: 24px;
}
.connectAccountBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #737373;
  border-radius: 16px;
  padding: 32px;
}
.connectAccountBlockWithError {
  border: 1px solid #ff6666;
}
.connectAccBttnDemoSavings {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-block: 8px;
  font-weight: 700;
  background: var(--Primary, #39848a);
}
.footerTextAutomation {
  padding-block-start: 24px;
  font-size: 18px;
  font-weight: 50;
  color: #fafafa;
}
.fixedBalanceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #303030;
  border-radius: 16px;
  padding: 32px;
  margin-block-end: 80px;
}
.inputForFixedBalance {
  display: flex;
  width: 194px;
  height: 52px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1.5px solid #48a6ad;
  background: rgba(57, 132, 138, 0.3);
  text-align: center;
}
.inputForFixedBalance:focus {
  outline: none;
}
.fixedBalanceInputError {
  border: 1.5px solid #ff6666;
}
.errorForFixedBalance {
  margin-block-start: 24px;
}
.continue_button {
  display: flex;
  padding: 16px 32px;
  align-self: center;
  gap: 8px;
  border-radius: 8px;
  opacity: 0.5;
  background: var(--Primary, #39848a);
}
.continue_button_holder {
  display: flex;
  justify-content: center;
}
.disabled_continue_button {
  cursor: not-allowed;
}
.title_automations_onboarding {
  color: var(--Greyscale-Greyscale-40, #fafafa);
  text-align: center;
  font-family: "Open Sans";
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-block-end: 24px;
}

.fixedBalanceSummary {
  align-self: center;
  width: 55%;
  text-align: center;
}
.nextButton_after_fixed_sum {
  margin-block-end: 80px;
}
.optimal_balance_subtitle {
  margin-block-end: 0px;
}
.automation_block {
  border-color: #737373;
  padding-inline: 32px !important;
  margin-block: 0px !important;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .automation_block {
    padding-block: 32px !important;
  }
  .optimal_balance_block {
    margin-block-start: 60px;
  }
  .optimal_balance_subtitle {
    margin-block-end: 4px;
  }
  .optimal_balance_title {
    font-size: 24px;
  }
  .footerTextAutomation {
    font-size: 16px;
  }
  .subtitle_block {
    margin-block-end: 60px !important;
  }
  .errorForFixedBalance {
    margin-block-start: 20px;
  }
  .automation_subtitle {
    margin-block-end: 60px;
    font-size: 16px;
  }
  .cache_from_subtitle {
    font-size: 16px;
  }
  .title_automations_onboarding {
    font-size: 24px !important;
  }
  .fixedBalanceSummary {
    width: 70%;
  }
  .demo_arrows_Icon {
    margin-block-end: 75px !important;
  }
  .modalAccountsList__title {
    font-size: 16px;
    margin: 20px 0;
    padding-top: 20px;
  }
  .error__message.account-exist {
    margin-top: 20px;
  }
  .error__message.account-exist.desktop-error {
    display: none;
  }

  .fixedBalanceBlock {
    margin-block-end: 60px;
  }
  .demo_connectionButton__holder {
    flex-direction: row !important;
  }
  .demo_arrows_Icon {
    transform: rotate(90deg) !important;
  }
  .error__message {
    margin-block: 20px 0px;
  }
  .page__subtitle.automation__description {
    margin-bottom: 20px;
  }
  .demo_connectionButton__holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .demo_arrows_Icon {
    min-width: 32px;
    transform: rotate(180deg);
  }
  .automation__container {
    padding: 16px;
    margin: 20px 0 24px 0;
  }
  .demo_arrows_Icon {
    margin-top: 0;
  }
  .wrong__choise-hide {
    display: block;
  }
  .wrong__choise {
    display: none;
  }
  .backButton {
    margin-top: 32px;
  }
}
@media screen and (max-width: 743px) {
  .optimal_balance_block {
    margin-block-start: 40px;
  }
  .optimal_balance_subtitle {
    margin-block-end: 12px;
  }
  .optimal_balance_title {
    font-size: 20px;
  }
  .subtitle_block {
    margin-block-end: 40px !important;
  }
  .error__message {
    margin-block-start: 16px;
  }
  .automation__container {
    margin: 16px 0 24px 0;
  }
  .page__subtitle.automation__description {
    margin-bottom: 16px;
  }
  .demo_connectionButton__holder {
    gap: 16px;
  }
  .backButton {
    margin-top: 24px;
  }
  .errorForFixedBalance {
    margin-block-start: 16px;
  }

  .automation_subtitle {
    margin-block-end: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .cache_from_subtitle {
    font-size: 14px;
  }
  .fixedBalanceSummary {
    width: 90%;
  }
  .demo_arrows_Icon {
    margin-block: 0px !important;
  }
  .demo_connectionButton__holder {
    flex-direction: column !important;
  }
  .title_automations_onboarding {
    margin-block-start: 0px !important;
    text-align: center;
    font-size: 20px !important;
  }

  .fixedBalanceBlock {
    margin-block-end: 40px;
  }
  .demo_arrows_Icon {
    margin-block-end: 0px;
    transform: rotate(180deg) !important;
  }
  .footerTextAutomation {
    font-size: 14px;
    text-align: center;
  }
  .modalAccountsList__title {
    font-size: 14px;
    margin: 16px 0;
    padding-top: 16px;
  }
  .error__message.account-exist {
    margin-top: 16px;
  }
  .addAutomation__nextButton {
    margin-top: 5px;
  }
  .modalAccountsList__container {
    max-width: 624px;
    min-height: 60%;
    width: 100%;
    margin: 0 auto;
    background-color: var(--secondary-black-color);
    border-top: 1px solid rgba(112, 112, 112, 1);
    padding: 10px;
    border-radius: 16px 16px 0 0;
    position: absolute;
    transform: translateY(-17%);
    bottom: 0;
  }
}

.add-automation-dialog .add-automation-dialog-paper {
  background-color: #000;
  padding: 20px;
  border: 1px solid #707070;
  border-radius: 16px;
  margin: 0 10px;
  max-width: 624px;
  min-height: 490px;
  width: 100%;
  background-image: none;
  box-shadow: none;
  overflow: hidden;
}

.add-automation-dialog .MuiBackdrop-root {
  background-color: rgba(112, 112, 112, 0.5);
}
