input[name="loginEmail"]:-webkit-autofill,
input[name="firstName"]:-webkit-autofill,
input[name="lastName"]:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}

.customInput__holder {
  position: relative;
  max-width: 570px;
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
}

.address-container .customInput__holder {
  margin-bottom: 16px;
}

.customInput__holder input.Mui-disabled {
  -webkit-text-fill-color: initial;
}

@media screen and (max-width: 1024px) {
  .customInput__holder {
    max-width: 624px;
    margin-bottom: 20px;
  }
  .address-container .customInput__holder {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 743px) {
  .customInput__holder {
    margin-bottom: 16px;
  }
}

.customInput {
  width: 100%;
  height: 52px;
  padding: 16px;
  margin: 0 auto;
  background-color: var(--grey10);
  border-radius: 8px;
  transition: all 0.3s ease;
}
.isActive{
  border: 1px solid var(--primary-color);
}
.inputError {
  border: 1px solid #ff6666 !important; 
}
.customInput__label {
  position: absolute;
  font-size: 14px;
  top: 16px;
  left: 16px;
  transition: all 0.3s ease;
}
.labelError {
  color: var(--grey40);
}
.customInput:focus{
  border: 1px solid var(--primary-color);
}
.customInput:focus,
.customInput:valid,
.customInput:disabled,
.customInput:read-only {
  padding: 16px 16px 8px 16px;
  
}
.customInput:focus ~ .customInput__label,
.customInput:valid ~ .customInput__label,
.customInput:read-only ~ .customInput__label
 {
  font-size: 8px;
  transform: translateY(-10px);
}

.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.customInput:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 40px 40px var(--grey10);
}
.password__eyeIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 24px;
}
.password__eyeIcon svg {
  color: var(--grey30);
}

.eyeError a{
  color: #ff6666;
}

.recovery__inputHolder {
  width: 100%;
  max-width: 570px;
}

@media screen and (max-width: 1024px) {
  .recovery__inputHolder {
    max-width: 624px;
  }
}

.customInput__holder label,
.passwordInput label,
.recovery__inputHolder label,
.balanceInput__holder label,
.dateBirth__input__holder label {
  cursor: text;
}

