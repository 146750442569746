.verifyEmail__infoContainer .customInput__holder {
    margin-bottom: 0;
}

.otp-input-container {
    display: flex;
    margin: 0 auto 24px;
    gap: 16px;
    justify-content: center;
}

.verifyEmail__infoContainer .error__msg {
    margin-bottom: 36px;
}

@media screen and (max-width: 1024px) {
    .otp-input-container {
        margin: 0 auto 20px;
    }
    .verifyEmail__infoContainer .error__msg {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 743px) {
    .otp-input-container {
        margin: 0 auto 16px;
    }
    .verifyEmail__infoContainer .error__msg {
        margin-bottom: 24px;
    }
}

.otp-input-input {
    width: 100%;
    max-width: 72px;
    height: 80px;
    border-radius: 8px;
    background-color: #303030;
    text-align: center;
}

.otp-input-input.hasError {
    border: 1px solid #F66;
}

.otp-input-input:not(.hasError):focus {
    border: 1px solid #39848a;
}