.header__container {
  width: 100%;
  background-color: var(--secondary-black-color);
  border-bottom: 1px solid var(--greyscale-greyscale-20, #707070);
  height: 72px;
  
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  max-width: 1180px;
  margin: 0 auto;
}
.leftMenu {
  display: flex;
  align-items: center;
  gap: 40px;
}
.rightMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightMenu>a {
  margin-left: 28px;
}

.logo {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.headerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.headerMenu>li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

ul>li>svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.itemMenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: var(--grey40) !important;
  display: flex;
  align-items: center;
}

.itemMenu span {
  margin-left: 8px;
}

.notificationsBtn {
  cursor: pointer;
}

.logOutBtn {
  font-weight: 600;
  font-size: 18px;
  color: var(--grey10);
  margin-left: 10px;
}

.menu-btn {
  display: none;
  margin-top: 8px;
}

.select-menu-header {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  position: absolute;
  top: 10px;
  right: 0px;
  border-radius: 8px;
  border: 1px solid var(--greyscale-greyscale-20, #707070);
  background: var(--Background, #000);
}

.select-menu-header>.options>.option {
  display: flex;
  flex-direction: row;
  width: 144px;
  align-items: center;
  gap: 8px;
  color: var(--grey40);
  /* Body/Body Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.cornerHeader {
  position: absolute;
  right: 4.5px;
  top: -13px;
}

/* MEDIA */

@media screen and (max-width: 1200px) {
  .header {
    padding: 16px 60px;
  }
}

@media screen and (max-width: 1024px) {
  .headerMenu>li {
    padding-right: 0px;
  }

  .leftMenu {
    gap: 10px;
  }

  .rightMenu>a {
    margin-left: 15px;
  }
}

@media screen and (max-width: 743px) {
  .header {
    padding: 16px 20px;
  }

  .headerMenu {
    display: none;
  }

  .headerHelpBtn,
  .logOutBtn {
    display: none;
  }

  .menu-btn {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 99;
    overflow: hidden;
    margin-left: 24px;
  }
}