.passwod__errorMessage {
  color: var(--error);
  font-size: 18px;
  text-align: center;
}
/* MEDIA */
@media screen and (max-width: 1024px) {
  .passwod__errorMessage {
    font-size: 16px;
  }
}
@media screen and (max-width: 743px) {
  .passwod__errorMessage {
    font-size: 14px;
  }
}
