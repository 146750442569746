.signupHeader {
  border-bottom: 1px solid #707070;
  height: 72px;
}
.signupHeader__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.logo__icon {
  padding: 8px;
}
.signupHeader__btnHolder {
  display: flex;
  gap: 16px;
}
@media screen and (max-width: 345px) {
  .signupHeader__btnHolder {
    gap: 5px;
  }
  .header-container.MuiContainer-root {
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}
.logout__btn {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
}
.logout__btn img {
  margin-right: 4px;
}
