/* Desktop  */
.fiveStarsBox {
  padding-block-end: 40px !important;
  height: 77px !important;
  display: flex !important;
  gap: 8px !important;
  justify-content: center !important;
}

.fiveStars {
  width: 45px !important;
  height: 43px !important;
  flex-shrink: 0 !important;
  display: flex !important;
}

.reviewTitle {
  font-size: 40px !important;
  padding-block-end: 24px !important;
}
.reviewSubtitle {
  font-size: 28px !important;
  padding-block-end: 24px !important;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .fiveStarsBox {
    padding-block-end: 40px !important;
    height: 70px !important;
    display: flex !important;
    gap: 8px !important;
    justify-content: center !important;
  }
  .reviewTitle {
    font-size: 32px !important;
    padding-block-end: 24px !important;
  }
  .reviewSubtitle {
    font-size: 24px !important;
    padding-block-end: 24px !important;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .fiveStarsBox {
    padding-block-end: 32px !important;
    height: 62px !important;
    display: flex !important;
    gap: 8px !important;
    justify-content: center !important;
  }
  .fiveStars {
    display: flex !important;
  }
  .reviewTitle {
    font-size: 24px !important;
    padding-block-end: 16px !important;
  }
  .reviewSubtitle {
    font-size: 18px !important;
    padding-block-end: 16px !important;
  }
}
