.saving_graph__container .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 24px;
    margin-top: 80px;
}

.saving_graph__container .circle_graph {
    display: flex;
    align-items: center;
    justify-content: center;
}

.saving_graph__container .graph_amount {
    color: #FAFAFA;
    text-align: center;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.saving_graph__container .graph_helper {
    color: #FAFAFA;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.row_info span {
    color: #FAFAFA;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-left: 10px;
}

.row_info img {
    width: 24px;
    height: 24px;
    color: #FAFAFA;
    border-radius: 2px;
    padding: 4px;
}

.saving_graph__container .graph_info {
    margin-left: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .saving_graph__container .title {
        font-size: 28px;
        line-height: 36px;
        margin-top: 60px;
        margin-bottom: 20px;
    }
    .saving_graph__container .graph_amount {
        font-size: 28px;
    }
    .saving_graph__container .graph_helper,
    .row_info span {
        font-size: 16px;
    }
    .row_info img {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 743px) {
    .saving_graph__container .graph_info {
        margin-left: 0;
    }
    .saving_graph__container .title {
        font-size: 24px;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 16px;
    }
    .saving_graph__container .graph_amount {
        font-size: 24px;
    }
    .saving_graph__container .graph_helper,
    .row_info span {
        font-size: 14px;
    }
    .row_info img {
        width: 16px;
        height: 16px;
    }
    .saving_graph__container .circle_graph {
        flex-direction: column;
    }
    .saving_graph__container .graph {
        margin-right: 0;
    }
    .saving_graph__container .graph_info {
        margin-left: 0;
        margin-top: 50px;
    }
}

.saving_graph__container .graph {
    width: 224px;
    height: 224px;
    background-image: url("../../assets/ring.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;
}

.saving_graph__container .graph.no-graph-info {
    margin-right: 0;
}

.saving_graph__container .row_info {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 12px;
}

.row_info img.equal {
    background-color: #707070;
}

.row_info img.more {
    background-color: #39848A;
}

.row_info img.less {
    background-color: #EB853F;
}