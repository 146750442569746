.notifications__container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.notifications__block {
  display: flex;
  justify-content: right;
  width: 100%;
  height: 100%;
  max-width: 1050px;
  margin: 0 auto;
}

.notifications {
  display: flex;
  width: 650px;
  max-height: 525px;
  height: fit-content;
  padding: 40px 10px 40px 40px;
  flex-direction: column;
  align-items: normal;
  gap: 24px;
  left: calc(100% - 771px);
  top: 72px;
  border-radius: 12px;
  border: 1px solid var(--grey20);
  background: var(--grey10);
  /* overflow-y: auto; */
}
.notifications.empty {
  padding: 40px 40px 40px 40px;
}
.notif_block{
  overflow-y: auto;
  padding-right: 22px;
}

.notif_block::-webkit-scrollbar {
  width: 8px;
}

.notif_block::-webkit-scrollbar-track {
  /* background: var(--secondary-black-color); */
}

.notif_block::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.cornerNotifications {
  position: absolute;
  right: calc((100% - 1050px) / 2 + 67px);
  top: 59px;
  z-index: 99;
}

.notifications__closeBtn {
  position: absolute;
  right: 20px;
  top: 25px;
}

.notifications__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.notifications__header__rightBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.notifications__header__title {
  color: var(--grey40);
  text-align: center;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.notifications__header__readAll {
  color: var(--primary-color);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 4px;
}

.notification__infoBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  color: var(--grey40);
  margin: 0px 0px 24px 0px;
}
.notifications__container{

}

.notifications__header__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-top: 24px;
}

.notification__info__text {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.notification__info__date {
  text-align: right;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.notifications__latest {
  color: var(--primary-color);
  text-align: right;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  align-self: flex-end;
  cursor: pointer;
}

.notification__line {
  width: 100%;
  border-bottom: 1px solid var(--grey40);
}

.notifications__empty {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  
}
.notifications__empty-title {
  font-size: 18px;
  color: var(--grey40);
  font-weight: 700;
  text-align: end;
}
.reconnectNotification {
  font-size: 16px;
  color: var(--error);
  font-weight: 700;
  text-align: end;
  cursor: pointer;
}
@media screen and (max-width: 1180px) {
  .cornerNotifications {
    right: 123px;
  }
}

@media screen and (max-width: 1024px) {
  .notification__infoBlock {
    margin: 0px 0px 20px 0px;
  }
  .cornerNotifications {
    right: 97px;
  }
  .notifications__empty-title {
    font-size: 16px;
  }
  .notifications__header__title {
    font-size: 28px;
  }
  .notifications {
    gap: 20px;
  }
}

@media screen and (max-width: 743px) {
  .notifications__arrow::before {
    right: 63px !important;
  }
  .notif_block{
    padding-right: 12px;
  }
  .notification__infoBlock {
    margin: 0px 0px 16px 0px;
  }
  .notifications {
    height: fit-content;
    margin-right: 10px;
    margin-left: 10px;
    padding: 40px 0px 20px 20px;
    gap: 16px;
  }
  .notifications.empty {
    padding: 20px 20px 20px 20px;
  }

  .cornerNotifications {
    right: 73px;
  }

  .notification__info__text {
    font-size: 14px;
    max-width: 70%;
  }
  .notifications__empty-title {
    font-size: 14px;
  }

  .notification__info__date,
  .notifications__header__readAll {
    font-size: 12px;
  }

  .notifications__header__title {
    font-size: 24px;
  }
}
