input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.dateBirth__input__holder {
  position: relative;
  max-width: 570px;
  margin: 0 auto 24px;
}

@media screen and (max-width: 1024px) {
  .dateBirth__input__holder {
    max-width: 624px;
    margin: 0 auto 20px;
  }
}

@media screen and (max-width: 743px) {
  .dateBirth__input__holder {
    margin: 0 auto 16px;
  }
}
