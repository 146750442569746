.accountCard {
  background-color: var(--grey10);
  padding: 32px;
  border-radius: 16px;
  min-height: 256px;
  /* height: 100%;
  max-height: 256px; */
  width: 100%;
  cursor: pointer;
}

.accountCard.card-with-error {
  border: 1px solid var(--error);
}
.accountCard__info {
  text-align: start;
}
.accountCard__data-bank {
  flex: 1;
}
.accountCard-disabled {
  cursor: default;
  opacity: 0.5;
}
.accountCard__data {
  display: flex;
  align-items: center;
}

.accountCard-selected {
  border: 1px solid var(--grey10);
  background-color: var(--grey20);
}
.accountCard__type {
  font-weight: 700;
  font-size: 18px;
}
.accountCard__type-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.accountCard__type-holder img {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
}

.accountCard__number {
  font-size: 18px;
  font-weight: 400;
  color: var(--grey30);
  margin-bottom: 8px;
}

.accountCard__value {
  font-size: 32px;
  font-weight: 700;
  color: var(--grey30);
  line-height: 40px;
}

.accountCard__bankLogo img {
  width: 60px;
}

.active__button {
  border: 1px solid var(--primary-color);
}
.disconnected_button {
  border: 1px solid var(--error);
}

/* MEDIA */

@media screen and (max-width: 1024px) {
  .accountCard__type-holder img {
    width: 33px;
    height: 33px;
  }
  .accountCard__number {
    font-size: 16px;
  }
  .accountCard {
    min-height: auto;
    padding: 32px;
  }
  .accountCard__value {
    font-size: 28px;
    line-height: 32px;
  }
  .accountCard__bankLogo img {
    width: 50px;
  }
  .accountCard__type {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .type_dashboard {
    margin-block-end: 16px !important;
  }
  .accountCard__type {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .accountCard {
    min-height: auto;
    padding: 16px;
  }
  .accountCard__type-holder img {
    width: 27px;
    height: 27px;
  }
  .accountCard__number {
    font-size: 14px;
  }
  .accountCard__value {
    font-size: 24px;
  }
  .accountCard__bankLogo img {
    width: 40px;
  }
  .accountCard__type {
    font-size: 14px;
  }
}
