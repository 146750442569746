
.demo_button_holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding-block-start: 40px;
}

.demo_button {
  width: 50%;
  padding: 32px;
}
@media screen and (max-width: 1024px) {
  .demo_button {
    width: 85%;
  }
  .demo_button_holder {
    padding-block-start: 20px;
    gap: 60px;
  }
}
@media screen and (max-width: 743px) {
  .demo_welcome_container {
    /* min-width: 430px; */
    padding-inline: 16px;
  }
  .demo_button {
    text-wrap: nowrap;
    width: 95%;
  }
  .demo_button_holder {
    padding-block-start: 16px;
  }
}
