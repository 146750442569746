.confirm__infoContainer {
  flex: 1 1;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm__infoContainer .passwod__errorMessage {
  margin-bottom: 20px;
}

.confirm__infoContainer .password__description {
  color: var(--grey30);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 24px;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .confirm__infoContainer .password__description {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .confirm__infoContainer .password__description {
    margin-bottom: 16px;
    font-size: 14px;
  }
}
