.divider {
  height: 1px;
  background: #707070;
}
.iconsContainer {
  display: flex;
  justify-content: center;
  padding-block-start: 40px;
  gap: 40px;
}

.blackBoxFooter {
  margin-block: 80px;
}

.links__list {
  display: flex !important;
  justify-content: center;
  gap: 80px !important;
}

@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .iconsContainer {
    gap: 32px;
  }
  .blackBoxFooter {
    margin-block: 70px;
  }
  .links__list {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    gap: 20px !important;
  }
}
@media screen and (max-width: 743px) {
  .iconsContainer {
    padding-block-start: 24px;
    gap: 24px;
  }
  .blackBoxFooter {
    margin-block: 60px;
  }
  .links__list {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    gap: 20px !important;
  }
  .divider {
    margin: 0;
  }
}
