.backButton {
  margin-top: 40px;
}
.error__message {
  margin-block-start: 24px;
}
.automation__container {
  width: 100%;
  border: 1px solid var(--grey10);
  padding: 32px;
  border-radius: 16px;
  margin: 24px 0 24px 0;
  flex-grow: 1;
}
.page__subtitle.automation__description {
  margin-bottom: 24px;
}
.connectionButton__holder {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.arrows__icon {
  margin-top: 115px;
  min-width: 32px;
  transform: rotate(90deg);
  z-index: 0;
}
.btn-holder{
  padding: 40px;
  margin: 0 auto;
}
.wrong__choise{
  color: var(--error);
  max-width: 650px;
  text-align: center;
  margin: 10px auto;
}
.wrong__choise-hide{
  display: none;
  color: var(--error);
  max-width: 650px;
  text-align: center;
  margin: 10px auto;
}


/* MEDIA */
@media screen and (max-width: 1024px) {
  .error__message {
    margin-block: 20px 0px;
  }
  .page__subtitle.automation__description {
    margin-bottom: 20px;
  }
  .connectionButton__holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .arrows__icon {
    min-width: 32px;
    transform: rotate(180deg);
  }
  .automation__container {
    padding: 16px;
    margin: 20px 0 24px 0;
  }
  .arrows__icon{
    margin-top: 0;
  }
  .wrong__choise-hide{
    display: block;
  }
  .wrong__choise{
    display: none;
  }
  .backButton{
    margin-top: 32px;
  }
}

@media screen and (max-width: 743px) {
  .error__message {
    margin-block-start: 16px;
  }
  .automation__container {
    margin: 16px 0 24px 0;
  }
  .page__subtitle.automation__description {
    margin-bottom: 16px;
  }
  .connectionButton__holder {
    gap: 16px;
  }
  .backButton{
    margin-top: 24px;
  }
}
/* END */
